import React from "react";
import { HashLink } from "react-router-hash-link";

const ServicesImage = () => {
  return (
    <svg
      className="services-img"
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1134.12 703.96"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="513.21"
          y1="681.63"
          x2="840.42"
          y2="681.63"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6fc1c6" />
          <stop offset="0.12" stopColor="#6bb8c3" />
          <stop offset="0.33" stopColor="#609ebb" />
          <stop offset="0.59" stopColor="#4d75ad" />
          <stop offset="0.9" stopColor="#343c9a" />
          <stop offset="0.99" stopColor="#2c2994" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="0"
          y1="282.23"
          x2="244.54"
          y2="282.23"
          xmlnshref="#linear-gradient"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6fc1c6" />
          <stop offset="0.12" stopColor="#6bb8c3" />
          <stop offset="0.33" stopColor="#609ebb" />
          <stop offset="0.59" stopColor="#4d75ad" />
          <stop offset="0.9" stopColor="#343c9a" />
          <stop offset="0.99" stopColor="#2c2994" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="756.06"
          y1="541.49"
          x2="1083.27"
          y2="541.49"
          xmlnshref="#linear-gradient"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6fc1c6" />
          <stop offset="0.12" stopColor="#6bb8c3" />
          <stop offset="0.33" stopColor="#609ebb" />
          <stop offset="0.59" stopColor="#4d75ad" />
          <stop offset="0.9" stopColor="#343c9a" />
          <stop offset="0.99" stopColor="#2c2994" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="756.06"
          y1="821.89"
          x2="1083.27"
          y2="821.89"
          xmlnshref="#linear-gradient"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6fc1c6" />
          <stop offset="0.12" stopColor="#6bb8c3" />
          <stop offset="0.33" stopColor="#609ebb" />
          <stop offset="0.59" stopColor="#4d75ad" />
          <stop offset="0.9" stopColor="#343c9a" />
          <stop offset="0.99" stopColor="#2c2994" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="998.91"
          y1="401.3"
          x2="1326.12"
          y2="401.3"
          xmlnshref="#linear-gradient"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6fc1c6" />
          <stop offset="0.12" stopColor="#6bb8c3" />
          <stop offset="0.33" stopColor="#609ebb" />
          <stop offset="0.59" stopColor="#4d75ad" />
          <stop offset="0.9" stopColor="#343c9a" />
          <stop offset="0.99" stopColor="#2c2994" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-6"
          x1="888.71"
          y1="281.45"
          x2="1134.12"
          y2="281.45"
          xmlnshref="#linear-gradient"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6fc1c6" />
          <stop offset="0.12" stopColor="#6bb8c3" />
          <stop offset="0.33" stopColor="#609ebb" />
          <stop offset="0.59" stopColor="#4d75ad" />
          <stop offset="0.9" stopColor="#343c9a" />
          <stop offset="0.99" stopColor="#2c2994" />
        </linearGradient>
      </defs>

      <g className="cls-1">
        <HashLink to="/Services/#project">
          <polygon
            className="cls-2"
            points="485.84 422.03 404.86 562.28 242.91 562.28 161.94 422.03 242.91 281.78 404.86 281.78 485.84 422.03"
          />
        </HashLink>
      </g>
      <g className="cls-1">
        <HashLink to="/Services/#izg">
          <polygon
            className="cls-2"
            points="730.17 281.78 649.2 422.03 487.25 422.03 406.28 281.78 487.25 141.53 649.2 141.53 730.17 281.78"
          />
        </HashLink>
      </g>
      <g className="cls-1">
        <HashLink to="/Services/#post">
          <polygon
            className="cls-2"
            points="971.43 142.19 890.45 282.44 728.5 282.44 647.53 142.19 728.5 1.94 890.45 1.94 971.43 142.19"
          />
        </HashLink>
      </g>
      <g className="cls-1">
        <HashLink to="/Services#other">
          <polygon
            className="cls-2"
            points="728.58 563.7 647.61 703.95 485.66 703.95 404.68 563.7 485.66 423.45 647.61 423.45 728.58 563.7"
          />
        </HashLink>
      </g>
      <path
        className="cls-3"
        d="M758.62,823.32H595l-.43-.75L513.21,681.63,595,540H758.62l.43.75,81.37,140.93Zm-161.88-3H756.89L837,681.63l-80.08-138.7H596.74l-80.08,138.7Z"
        transform="translate(-353.04 -259.62)"
      />
      <polygon
        onClick={() => alert("hello")}
        className="cls-4"
        points="162.74 423.54 0 423.54 0 420.55 161.02 420.55 241.1 281.85 160.59 142.41 163.17 140.91 244.54 281.85 162.74 423.54"
      />
      <path
        className="cls-5"
        d="M1001.47,683.18H837.87l-.44-.75L756.06,541.49l81.81-141.68h163.6l.43.74,81.37,140.94-.43.75Zm-161.88-3H999.75l80.07-138.7-80.07-138.7H839.59l-80.08,138.7Z"
        transform="translate(-353.04 -259.62)"
      />
      <path
        className="cls-6"
        d="M1001.47,963.58H837.87l-.44-.75L756.06,821.89l81.81-141.68h163.6l.43.75,81.37,140.93-.43.75Zm-161.88-3H999.75l80.07-138.7L999.75,683.2H839.59L759.51,821.89Z"
        transform="translate(-353.04 -259.62)"
      />
      <path
        className="cls-7"
        d="M1244.31,543h-163.6l-.43-.74L998.91,401.3l.43-.75,81.37-140.93h163.6l.44.74,81.37,140.94-.43.75Zm-161.88-3h160.16l80.08-138.7-80.08-138.7H1082.43l-80.07,138.7Z"
        transform="translate(-353.04 -259.62)"
      />
      <polygon
        className="cls-8"
        points="970.08 422.76 888.71 281.83 889.14 281.08 970.51 140.14 1134.12 140.14 1134.12 143.13 972.24 143.13 892.16 281.83 972.67 421.27 970.08 422.76"
      />

      <path
        className="cls-9"
        d="M246.6,412.9c0,0.6-0.1,1.1-0.3,1.6s-0.5,0.9-0.8,1.3c-0.5,0.4-1,0.8-1.6,1s-1.4,0.3-2.3,0.3h-1.5v4.9h-1.7
		v-13.1h3.3c0.8,0,1.4,0.1,2,0.2s1,0.3,1.4,0.6c0.5,0.3,0.9,0.8,1.1,1.3C246.5,411.5,246.6,412.1,246.6,412.9z M244.8,412.9
		c0-0.5-0.1-0.8-0.2-1.2s-0.4-0.6-0.7-0.8c-0.3-0.2-0.6-0.3-1-0.4s-0.8-0.1-1.4-0.1h-1.4v5.2h1.2c0.7,0,1.3-0.1,1.7-0.2
		c0.4-0.1,0.8-0.3,1.1-0.6c0.3-0.3,0.4-0.6,0.6-0.9S244.8,413.3,244.8,412.9z"
      />
      <path
        className="cls-9"
        d="M253.4,414L253.4,414c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.3,0-0.5,0c-0.4,0-0.9,0.1-1.3,0.3s-0.9,0.5-1.2,0.9v7
		h-1.7v-9.8h1.7v1.5c0.6-0.5,1.1-0.9,1.6-1.1c0.4-0.2,0.9-0.3,1.3-0.3c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0V414z"
      />
      <path
        className="cls-9"
        d="M262.7,417.1c0,1.7-0.4,2.9-1.2,3.8s-1.9,1.3-3.2,1.3c-1.4,0-2.4-0.5-3.2-1.4c-0.8-0.9-1.2-2.2-1.2-3.8
		c0-1.6,0.4-2.9,1.2-3.8s1.9-1.4,3.2-1.4c1.3,0,2.4,0.5,3.2,1.4C262.3,414.2,262.7,415.5,262.7,417.1z M260.9,417.1
		c0-1.3-0.2-2.2-0.7-2.8s-1.1-0.9-2-0.9c-0.9,0-1.5,0.3-2,0.9s-0.7,1.6-0.7,2.8c0,1.2,0.2,2.2,0.7,2.8c0.5,0.6,1.1,1,2,1
		c0.8,0,1.5-0.3,2-0.9C260.7,419.3,260.9,418.4,260.9,417.1z"
      />
      <path
        className="cls-9"
        d="M267,422.3c0,1.1-0.3,2-0.9,2.6s-1.4,0.9-2.5,0.9c-0.2,0-0.4,0-0.6,0s-0.4,0-0.7-0.1v-1.5h0.1
		c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.4,0c0.4,0,0.8-0.1,1.1-0.2s0.5-0.3,0.6-0.5c0.1-0.2,0.2-0.5,0.3-0.8s0.1-0.7,0.1-1.2v-8.1
		h-1.8v-1.4h3.4V422.3z M267,410.5h-1.9v-1.7h1.9V410.5z"
      />
      <path
        className="cls-9"
        d="M273.7,422.2c-1.6,0-2.8-0.4-3.6-1.3s-1.3-2.1-1.3-3.8c0-1.6,0.4-2.9,1.3-3.8s1.9-1.4,3.3-1.4
		c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8s0.6,0.8,0.8,1.4c0.2,0.5,0.3,1.2,0.3,2v0.9h-6.8c0,1.1,0.3,2,0.9,2.6s1.4,0.9,2.4,0.9
		c0.4,0,0.7,0,1.1-0.1s0.7-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.8-0.4s0.4-0.2,0.5-0.4h0.1v1.8c-0.2,0.1-0.4,0.2-0.7,0.3
		c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.2-0.9,0.2S274.1,422.2,273.7,422.2z M275.6,416c0-0.4-0.1-0.8-0.2-1.2s-0.2-0.6-0.4-0.8
		c-0.2-0.2-0.4-0.4-0.7-0.6s-0.7-0.2-1.1-0.2c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0.3-0.8,0.6c-0.2,0.3-0.4,0.5-0.6,0.9
		c-0.1,0.3-0.2,0.7-0.2,1.1H275.6z"
      />
      <path
        className="cls-9"
        d="M282.8,422.2c-0.7,0-1.3-0.1-1.9-0.3c-0.6-0.2-1-0.5-1.5-0.9s-0.7-1-0.9-1.6c-0.2-0.6-0.3-1.4-0.3-2.3
		c0-0.9,0.1-1.6,0.3-2.2s0.5-1.2,0.9-1.6s0.9-0.8,1.4-1c0.6-0.2,1.2-0.3,1.9-0.3c0.5,0,1.1,0.1,1.6,0.2s0.9,0.3,1.3,0.5v1.9h-0.1
		c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.3-0.7-0.4c-0.2-0.1-0.5-0.2-0.8-0.3s-0.6-0.1-0.9-0.1c-0.9,0-1.5,0.3-2.1,1
		c-0.5,0.6-0.8,1.6-0.8,2.7c0,1.2,0.3,2.1,0.8,2.7s1.2,1,2.1,1c0.5,0,1.1-0.1,1.5-0.3s0.9-0.5,1.3-0.9h0.1v1.9
		c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2c-0.3,0.1-0.5,0.1-0.8,0.2C283.4,422.2,283.1,422.2,282.8,422.2z"
      />
      <path
        className="cls-9"
        d="M290.2,422.2c-0.9,0-1.6-0.3-2.1-0.8c-0.5-0.5-0.8-1.3-0.8-2.4v-5.4h-1.1v-1.4h1.1v-2.8h1.7v2.8h3v1.4h-3v4.7
		c0,0.5,0,0.9,0,1.2s0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5s0.5,0.2,0.9,0.2c0.3,0,0.5,0,0.8-0.1c0.3-0.1,0.4-0.1,0.5-0.2h0.1v1.5
		c-0.3,0.1-0.6,0.2-0.9,0.2C290.7,422.2,290.4,422.2,290.2,422.2z"
      />
      <path
        className="cls-9"
        d="M306.4,422h-1.7v-1c-0.3,0.2-0.5,0.4-0.7,0.5s-0.4,0.3-0.7,0.4c-0.2,0.1-0.5,0.2-0.7,0.3
		c-0.3,0.1-0.5,0.1-0.9,0.1c-0.5,0-1-0.1-1.5-0.3s-0.8-0.5-1.2-1c-0.3-0.4-0.6-1-0.7-1.6s-0.3-1.4-0.3-2.2c0-0.9,0.1-1.6,0.3-2.2
		s0.5-1.2,0.9-1.6c0.4-0.4,0.8-0.8,1.3-1s1-0.4,1.6-0.4c0.5,0,0.9,0.1,1.3,0.2c0.4,0.1,0.7,0.3,1.2,0.5v-4.3h1.7V422z M304.8,419.6
		V414c-0.4-0.2-0.8-0.3-1.1-0.4s-0.7-0.1-1.1-0.1c-0.8,0-1.5,0.3-2,1c-0.5,0.6-0.7,1.6-0.7,2.8c0,1.2,0.2,2,0.6,2.7s1,0.9,1.8,0.9
		c0.4,0,0.9-0.1,1.3-0.3C304,420.2,304.4,419.9,304.8,419.6z"
      />
      <path
        className="cls-9"
        d="M313.1,422.2c-1.6,0-2.8-0.4-3.6-1.3s-1.3-2.1-1.3-3.8c0-1.6,0.4-2.9,1.3-3.8s1.9-1.4,3.3-1.4
		c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8s0.6,0.8,0.8,1.4c0.2,0.5,0.3,1.2,0.3,2v0.9h-6.8c0,1.1,0.3,2,0.9,2.6s1.4,0.9,2.4,0.9
		c0.4,0,0.7,0,1.1-0.1s0.7-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.8-0.4s0.4-0.2,0.5-0.4h0.1v1.8c-0.2,0.1-0.4,0.2-0.7,0.3
		c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.2-0.9,0.2S313.5,422.2,313.1,422.2z M315,416c0-0.4-0.1-0.8-0.2-1.2s-0.2-0.6-0.4-0.8
		c-0.2-0.2-0.4-0.4-0.7-0.6s-0.7-0.2-1.1-0.2c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0.3-0.8,0.6c-0.2,0.3-0.4,0.5-0.6,0.9
		c-0.1,0.3-0.2,0.7-0.2,1.1H315z"
      />
      <path
        className="cls-9"
        d="M320.9,422.2c-0.7,0-1.3-0.1-1.8-0.3s-1-0.4-1.4-0.6v-1.9h0.1c0.1,0.1,0.3,0.2,0.5,0.4s0.4,0.3,0.8,0.4
		c0.3,0.1,0.6,0.2,0.9,0.3s0.7,0.1,1.1,0.1c0.3,0,0.5,0,0.8-0.1c0.3-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.4-0.4s0.1-0.4,0.1-0.7
		c0-0.4-0.1-0.7-0.3-0.9s-0.6-0.4-1.1-0.5c-0.2-0.1-0.5-0.1-0.8-0.2s-0.7-0.1-0.9-0.2c-0.8-0.2-1.3-0.6-1.6-1
		c-0.3-0.5-0.5-1-0.5-1.6c0-0.9,0.3-1.6,1-2.2s1.6-0.8,2.7-0.8c0.5,0,1.1,0.1,1.6,0.2s1,0.3,1.4,0.5v1.8h-0.1
		c-0.4-0.3-0.9-0.6-1.4-0.8s-1-0.3-1.6-0.3c-0.5,0-1,0.1-1.4,0.3s-0.6,0.6-0.6,1c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.6,0.4,1.1,0.5
		c0.2,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.6,0.1,1,0.2c0.7,0.2,1.3,0.5,1.6,0.9s0.5,1,0.5,1.7c0,0.4-0.1,0.8-0.3,1.2
		c-0.2,0.4-0.4,0.7-0.7,1c-0.4,0.3-0.7,0.5-1.2,0.7S321.6,422.2,320.9,422.2z"
      />
      <path
        className="cls-9"
        d="M328.1,410.5h-1.9v-1.7h1.9V410.5z M328,422h-1.7v-9.8h1.7V422z"
      />
      <path
        className="cls-9"
        d="M330.8,423.6c0.1,0,0.3,0.1,0.5,0.2c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0.1,0.6,0.1,0.8,0.2s0.5,0.1,0.8,0.1
		c0.5,0,0.9-0.1,1.3-0.2s0.6-0.3,0.8-0.6c0.2-0.2,0.3-0.5,0.4-0.8s0.1-0.7,0.1-1.1v-0.9c-0.4,0.4-0.9,0.7-1.3,0.9
		c-0.4,0.2-1,0.3-1.6,0.3c-1.1,0-2-0.4-2.7-1.2s-1-2-1-3.6c0-0.8,0.1-1.5,0.3-2.2c0.2-0.6,0.5-1.2,0.9-1.6c0.4-0.4,0.8-0.7,1.3-1
		s1-0.3,1.6-0.3c0.5,0,1,0.1,1.3,0.2c0.4,0.1,0.7,0.3,1.1,0.5l0.1-0.4h1.6v8.7c0,1.7-0.4,2.9-1.1,3.7s-1.8,1.2-3.3,1.2
		c-0.5,0-1,0-1.5-0.1s-1-0.2-1.4-0.3L330.8,423.6L330.8,423.6z M336.3,419.3V414c-0.4-0.2-0.8-0.3-1.1-0.4s-0.7-0.1-1-0.1
		c-0.8,0-1.5,0.3-2,0.9s-0.7,1.5-0.7,2.6c0,1.1,0.2,1.9,0.5,2.5s1,0.9,1.8,0.9c0.4,0,0.9-0.1,1.3-0.3S336,419.6,336.3,419.3z"
      />
      <path
        className="cls-9"
        d="M348.1,422h-1.7v-5.6c0-0.5,0-0.9-0.1-1.3c0-0.4-0.1-0.7-0.3-0.9c-0.1-0.2-0.3-0.4-0.6-0.5s-0.6-0.2-1-0.2
		c-0.4,0-0.8,0.1-1.3,0.3c-0.4,0.2-0.9,0.5-1.3,0.9v7.3h-1.7v-9.8h1.7v1.1c0.5-0.4,1-0.8,1.5-1s1-0.4,1.5-0.4c1,0,1.7,0.3,2.3,1
		c0.5,0.6,0.8,1.6,0.8,2.8V422z"
      />
      <path
        className="cls-9"
        d="M367.9,412.2l-2.5,9.8h-1.5l-2.4-7.6l-2.4,7.6h-1.5l-2.5-9.8h1.7l1.7,7.6l2.3-7.6h1.4l2.4,7.6l1.6-7.6H367.9z"
      />
      <path
        className="cls-9"
        d="M377.5,417.1c0,1.7-0.4,2.9-1.2,3.8s-1.9,1.3-3.2,1.3c-1.4,0-2.4-0.5-3.2-1.4c-0.8-0.9-1.2-2.2-1.2-3.8
		c0-1.6,0.4-2.9,1.2-3.8s1.9-1.4,3.2-1.4c1.3,0,2.4,0.5,3.2,1.4C377.1,414.2,377.5,415.5,377.5,417.1z M375.8,417.1
		c0-1.3-0.2-2.2-0.7-2.8s-1.1-0.9-2-0.9c-0.9,0-1.5,0.3-2,0.9s-0.7,1.6-0.7,2.8c0,1.2,0.2,2.2,0.7,2.8c0.5,0.6,1.1,1,2,1
		c0.8,0,1.5-0.3,2-0.9C375.5,419.3,375.8,418.4,375.8,417.1z"
      />
      <path
        className="cls-9"
        d="M384.4,414L384.4,414c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.3,0-0.5,0c-0.4,0-0.9,0.1-1.3,0.3s-0.9,0.5-1.2,0.9v7
		h-1.7v-9.8h1.7v1.5c0.6-0.5,1.1-0.9,1.6-1.1c0.4-0.2,0.9-0.3,1.3-0.3c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0V414z"
      />
      <path
        className="cls-9"
        d="M393.8,422h-2.2l-3.7-4.7l-0.7,0.6v4.1h-1.7v-13.7h1.7v8l3.9-4.1h2.1l-4,4.1L393.8,422z"
      />
      <path
        className="cls-9"
        d="M397.3,422.2c-0.7,0-1.3-0.1-1.8-0.3s-1-0.4-1.4-0.6v-1.9h0.1c0.1,0.1,0.3,0.2,0.5,0.4s0.4,0.3,0.8,0.4
		c0.3,0.1,0.6,0.2,0.9,0.3s0.7,0.1,1.1,0.1c0.3,0,0.5,0,0.8-0.1c0.3-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.4-0.4s0.1-0.4,0.1-0.7
		c0-0.4-0.1-0.7-0.3-0.9s-0.6-0.4-1.1-0.5c-0.2-0.1-0.5-0.1-0.8-0.2s-0.7-0.1-0.9-0.2c-0.8-0.2-1.3-0.6-1.6-1
		c-0.3-0.5-0.5-1-0.5-1.6c0-0.9,0.3-1.6,1-2.2s1.6-0.8,2.7-0.8c0.5,0,1.1,0.1,1.6,0.2s1,0.3,1.4,0.5v1.8h-0.1
		c-0.4-0.3-0.9-0.6-1.4-0.8s-1-0.3-1.6-0.3c-0.5,0-1,0.1-1.4,0.3s-0.6,0.6-0.6,1c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.6,0.4,1.1,0.5
		c0.2,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.6,0.1,1,0.2c0.7,0.2,1.3,0.5,1.6,0.9s0.5,1,0.5,1.7c0,0.4-0.1,0.8-0.3,1.2
		c-0.2,0.4-0.4,0.7-0.7,1c-0.4,0.3-0.7,0.5-1.2,0.7S397.9,422.2,397.3,422.2z"
      />

      <path
        className="cls-9"
        d="M549.4,305c0.1,0,0.3,0.1,0.5,0.2s0.5,0.2,0.7,0.2c0.3,0.1,0.6,0.1,0.8,0.2s0.5,0.1,0.8,0.1
		c0.5,0,0.9-0.1,1.3-0.2s0.6-0.3,0.8-0.6c0.2-0.2,0.3-0.5,0.4-0.8s0.1-0.7,0.1-1.1v-0.9c-0.4,0.4-0.9,0.7-1.3,0.9s-1,0.3-1.6,0.3
		c-1.1,0-2-0.4-2.7-1.2s-1-2-1-3.6c0-0.8,0.1-1.5,0.3-2.2s0.5-1.2,0.9-1.6c0.4-0.4,0.8-0.7,1.3-1s1-0.3,1.6-0.3c0.5,0,1,0.1,1.3,0.2
		s0.7,0.3,1.1,0.5l0.1-0.4h1.6v8.7c0,1.7-0.4,2.9-1.1,3.7s-1.8,1.2-3.3,1.2c-0.5,0-1,0-1.5-0.1s-1-0.2-1.4-0.3L549.4,305L549.4,305z
		 M554.9,300.7v-5.4c-0.4-0.2-0.8-0.3-1.1-0.4s-0.7-0.1-1-0.1c-0.8,0-1.5,0.3-2,0.9s-0.7,1.5-0.7,2.6c0,1.1,0.2,1.9,0.5,2.5
		s1,0.9,1.8,0.9c0.4,0,0.9-0.1,1.3-0.3S554.6,301,554.9,300.7z"
      />
      <path
        className="cls-9"
        d="M567.6,303.6c-0.9,0-1.6-0.3-2.1-0.8s-0.8-1.3-0.8-2.4v-5.4h-1.1v-1.4h1.1v-2.8h1.7v2.8h3v1.4h-3v4.7
		c0,0.5,0,0.9,0,1.2s0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5s0.5,0.2,0.9,0.2c0.3,0,0.5,0,0.8-0.1s0.4-0.1,0.5-0.2h0.1v1.5
		c-0.3,0.1-0.6,0.2-0.9,0.2S567.8,303.6,567.6,303.6z"
      />
      <path
        className="cls-9"
        d="M578.7,298.5c0,1.7-0.4,2.9-1.2,3.8s-1.9,1.3-3.2,1.3c-1.4,0-2.4-0.5-3.2-1.4s-1.2-2.2-1.2-3.8
        c0-1.6,0.4-2.9,1.2-3.8s1.9-1.4,3.2-1.4c1.3,0,2.4,0.5,3.2,1.4S578.7,296.8,578.7,298.5z M577,298.5c0-1.3-0.2-2.2-0.7-2.8
        s-1.1-0.9-2-0.9c-0.9,0-1.5,0.3-2,0.9s-0.7,1.6-0.7,2.8c0,1.2,0.2,2.2,0.7,2.8s1.1,1,2,1c0.8,0,1.5-0.3,2-0.9S577,299.7,577,298.5z
        "
      />
      <path
        className="cls-9"
        d="M593.6,303.4h-1.7v-1c-0.3,0.2-0.5,0.4-0.7,0.5s-0.4,0.3-0.7,0.4c-0.2,0.1-0.5,0.2-0.7,0.3s-0.5,0.1-0.9,0.1
		c-0.5,0-1-0.1-1.5-0.3s-0.8-0.5-1.2-1c-0.3-0.4-0.6-1-0.7-1.6s-0.3-1.4-0.3-2.2c0-0.9,0.1-1.6,0.3-2.2s0.5-1.2,0.9-1.6
		c0.4-0.4,0.8-0.8,1.3-1s1-0.4,1.6-0.4c0.5,0,0.9,0.1,1.3,0.2s0.7,0.3,1.2,0.5v-4.3h1.7V303.4z M591.9,301v-5.7
		c-0.4-0.2-0.8-0.3-1.1-0.4s-0.7-0.1-1.1-0.1c-0.8,0-1.5,0.3-2,1s-0.7,1.6-0.7,2.8c0,1.2,0.2,2,0.6,2.6s1,0.9,1.8,0.9
		c0.4,0,0.9-0.1,1.3-0.3S591.5,301.3,591.9,301z"
      />
      <path
        className="cls-9"
        d="M601.2,295.3L601.2,295.3c-0.2,0-0.3-0.1-0.5-0.1s-0.3,0-0.5,0c-0.4,0-0.9,0.1-1.3,0.3s-0.9,0.5-1.2,0.9v7H596
		v-9.8h1.7v1.5c0.6-0.5,1.1-0.9,1.6-1.1s0.9-0.3,1.3-0.3c0.2,0,0.3,0,0.4,0s0.2,0,0.3,0V295.3z"
      />
      <path
        className="cls-9"
        d="M607.6,302.3c-0.1,0.1-0.3,0.2-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.2-0.6,0.3-0.8,0.4s-0.7,0.1-1.1,0.1
        c-0.4,0-0.8-0.1-1.2-0.2s-0.7-0.4-1-0.6c-0.3-0.3-0.5-0.6-0.6-1s-0.2-0.8-0.2-1.3c0-0.7,0.1-1.3,0.4-1.7s0.7-0.9,1.4-1.1
        c0.6-0.3,1.2-0.4,2-0.5s1.6-0.2,2.4-0.2v-0.3c0-0.4-0.1-0.7-0.2-0.9s-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.3s-0.6-0.1-0.9-0.1
        c-0.4,0-0.8,0.1-1.3,0.2s-1,0.3-1.5,0.5h-0.1v-1.7c0.3-0.1,0.7-0.2,1.3-0.3s1.1-0.2,1.7-0.2c0.7,0,1.2,0.1,1.7,0.2s0.9,0.3,1.2,0.6
        c0.4,0.3,0.6,0.6,0.8,1s0.3,0.9,0.3,1.6v6.7h-1.6V302.3z M607.6,301v-2.7c-0.4,0-0.9,0.1-1.5,0.1s-1.1,0.1-1.5,0.3
        c-0.4,0.1-0.8,0.3-1.1,0.6s-0.4,0.7-0.4,1.2c0,0.6,0.2,1,0.5,1.3s0.8,0.4,1.4,0.4c0.5,0,1-0.1,1.4-0.3S607.3,301.3,607.6,301z"
      />
      <path
        className="cls-9"
        d="M623.4,293.6l-2.5,9.8h-1.5l-2.4-7.6l-2.4,7.6h-1.5l-2.5-9.8h1.7l1.7,7.6l2.3-7.6h1.4l2.4,7.6l1.6-7.6H623.4z"
      />
      <path
        className="cls-9"
        d="M636.7,303.4H635v-5.6c0-0.5,0-0.9-0.1-1.3s-0.1-0.7-0.2-0.9c-0.1-0.2-0.3-0.4-0.6-0.5s-0.6-0.2-1-0.2
        c-0.4,0-0.8,0.1-1.3,0.3s-0.9,0.5-1.3,0.9v7.3H629v-9.8h1.7v1.1c0.5-0.4,1-0.8,1.5-1s1-0.4,1.5-0.4c1,0,1.7,0.3,2.3,1
        s0.8,1.6,0.8,2.8V303.4z"
      />

      <path
        className="cls-9"
        d="M639.4,305c0.1,0,0.3,0.1,0.5,0.2s0.5,0.2,0.7,0.2c0.3,0.1,0.6,0.1,0.8,0.2s0.5,0.1,0.8,0.1
        c0.5,0,0.9-0.1,1.3-0.2s0.6-0.3,0.8-0.6c0.2-0.2,0.3-0.5,0.4-0.8s0.1-0.7,0.1-1.1v-0.9c-0.4,0.4-0.9,0.7-1.3,0.9s-1,0.3-1.6,0.3
        c-1.1,0-2-0.4-2.7-1.2s-1-2-1-3.6c0-0.8,0.1-1.5,0.3-2.2s0.5-1.2,0.9-1.6c0.4-0.4,0.8-0.7,1.3-1s1-0.3,1.6-0.3c0.5,0,1,0.1,1.3,0.2
        s0.7,0.3,1.1,0.5l0.1-0.4h1.6v8.7c0,1.7-0.4,2.9-1.1,3.7s-1.8,1.2-3.3,1.2c-0.5,0-1,0-1.5-0.1s-1-0.2-1.4-0.3L639.4,305L639.4,305z
         M644.9,300.7v-5.4c-0.4-0.2-0.8-0.3-1.1-0.4s-0.7-0.1-1-0.1c-0.8,0-1.5,0.3-2,0.9s-0.7,1.5-0.7,2.6c0,1.1,0.2,1.9,0.5,2.5
        s1,0.9,1.8,0.9c0.4,0,0.9-0.1,1.3-0.3S644.6,301,644.9,300.7z"
      />
      <path
        className="cls-9"
        d="M626.7,291.9h-1.9v-1.7h1.9V291.9z M626.5,303.4h-1.7v-9.8h1.7V303.4z"
      />
      <path
        className="cls-9"
        d="M651.6,303.6c-0.7,0-1.3-0.1-1.8-0.3s-1-0.4-1.4-0.6v-1.9h0.1c0.1,0.1,0.3,0.2,0.5,0.4s0.4,0.3,0.8,0.4
		c0.3,0.1,0.6,0.2,0.9,0.3s0.7,0.1,1.1,0.1c0.3,0,0.5,0,0.8-0.1s0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.4-0.4s0.1-0.4,0.1-0.7
		c0-0.4-0.1-0.7-0.3-0.9s-0.6-0.4-1.1-0.5c-0.2-0.1-0.5-0.1-0.8-0.2s-0.7-0.1-0.9-0.2c-0.8-0.2-1.3-0.6-1.6-1s-0.5-1-0.5-1.6
		c0-0.9,0.3-1.6,1-2.2s1.6-0.8,2.7-0.8c0.5,0,1.1,0.1,1.6,0.2s1,0.3,1.4,0.5v1.8H655c-0.4-0.3-0.9-0.6-1.4-0.8s-1-0.3-1.6-0.3
		c-0.5,0-1,0.1-1.4,0.3s-0.6,0.6-0.6,1c0,0.4,0.1,0.7,0.3,0.9s0.6,0.4,1.1,0.5c0.2,0.1,0.5,0.1,0.8,0.2s0.6,0.1,1,0.2
		c0.7,0.2,1.3,0.5,1.6,0.9s0.5,1,0.5,1.7c0,0.4-0.1,0.8-0.3,1.2s-0.4,0.7-0.7,1c-0.4,0.3-0.7,0.5-1.2,0.7S652.3,303.6,651.6,303.6z"
      />

      <path
        className="cls-9"
        d="M480.6,272.6c0,0.6-0.1,1.1-0.3,1.6s-0.5,0.9-0.8,1.3c-0.5,0.4-1,0.8-1.6,1s-1.4,0.3-2.3,0.3h-1.5v4.9h-1.7
		v-13.1h3.3c0.8,0,1.4,0.1,2,0.2s1,0.3,1.4,0.6c0.5,0.3,0.9,0.8,1.1,1.3S480.6,271.9,480.6,272.6z M478.8,272.7
		c0-0.5-0.1-0.8-0.2-1.2s-0.4-0.6-0.7-0.8c-0.3-0.2-0.6-0.3-1-0.4s-0.8-0.1-1.4-0.1h-1.4v5.2h1.2c0.7,0,1.3-0.1,1.7-0.2
		s0.8-0.3,1.1-0.6c0.3-0.3,0.4-0.6,0.6-0.9S478.8,273.1,478.8,272.7z"
      />
      <path
        className="cls-9"
        d="M487.4,273.7L487.4,273.7c-0.2,0-0.3-0.1-0.5-0.1s-0.3,0-0.5,0c-0.4,0-0.9,0.1-1.3,0.3s-0.9,0.5-1.2,0.9v7
		h-1.7V272h1.7v1.5c0.6-0.5,1.1-0.9,1.6-1.1s0.9-0.3,1.3-0.3c0.2,0,0.3,0,0.4,0s0.2,0,0.3,0V273.7z"
      />
      <path
        className="cls-9"
        d="M496.6,276.9c0,1.7-0.4,2.9-1.2,3.8s-1.9,1.3-3.2,1.3c-1.4,0-2.4-0.5-3.2-1.4s-1.2-2.2-1.2-3.8
		c0-1.6,0.4-2.9,1.2-3.8s1.9-1.4,3.2-1.4c1.3,0,2.4,0.5,3.2,1.4S496.6,275.2,496.6,276.9z M494.9,276.9c0-1.3-0.2-2.2-0.7-2.8
		s-1.1-0.9-2-0.9c-0.9,0-1.5,0.3-2,0.9s-0.7,1.6-0.7,2.8c0,1.2,0.2,2.2,0.7,2.8s1.1,1,2,1c0.8,0,1.5-0.3,2-0.9
		S494.9,278.1,494.9,276.9z"
      />
      <path
        className="cls-9"
        d="M505.9,281.8h-1.7v-1c-0.3,0.2-0.5,0.4-0.7,0.5s-0.4,0.3-0.7,0.4c-0.2,0.1-0.5,0.2-0.7,0.3s-0.5,0.1-0.9,0.1
		c-0.5,0-1-0.1-1.5-0.3s-0.8-0.5-1.2-1c-0.3-0.4-0.6-1-0.7-1.6s-0.3-1.4-0.3-2.2c0-0.9,0.1-1.6,0.3-2.2s0.5-1.2,0.9-1.6
		c0.4-0.4,0.8-0.8,1.3-1s1-0.4,1.6-0.4c0.5,0,0.9,0.1,1.3,0.2s0.7,0.3,1.2,0.5v-4.3h1.7V281.8z M504.2,279.4v-5.7
		c-0.4-0.2-0.8-0.3-1.1-0.4s-0.7-0.1-1.1-0.1c-0.8,0-1.5,0.3-2,1s-0.7,1.6-0.7,2.8c0,1.2,0.2,2,0.6,2.6s1,0.9,1.8,0.9
		c0.4,0,0.9-0.1,1.3-0.3S503.9,279.7,504.2,279.4z"
      />
      <path
        className="cls-9"
        d="M515.9,281.8h-1.7v-1.1c-0.5,0.4-1,0.8-1.5,1s-1,0.3-1.5,0.3c-0.4,0-0.8-0.1-1.2-0.2s-0.7-0.4-1-0.7
		s-0.5-0.7-0.7-1.2s-0.2-1-0.2-1.7V272h1.7v5.6c0,0.5,0,1,0.1,1.3s0.1,0.6,0.3,0.9c0.1,0.3,0.3,0.4,0.5,0.6s0.6,0.2,1,0.2
		c0.4,0,0.8-0.1,1.3-0.3s0.9-0.5,1.3-0.8V272h1.7V281.8z"
      />
      <path
        className="cls-9"
        d="M522.2,282c-0.7,0-1.3-0.1-1.9-0.3s-1-0.5-1.5-0.9s-0.7-1-0.9-1.6s-0.3-1.4-0.3-2.3c0-0.9,0.1-1.6,0.3-2.2
		s0.5-1.2,0.9-1.6s0.9-0.8,1.4-1s1.2-0.3,1.9-0.3c0.5,0,1.1,0.1,1.6,0.2s0.9,0.3,1.3,0.5v1.9H525c-0.1-0.1-0.3-0.2-0.5-0.3
		s-0.4-0.3-0.7-0.4c-0.2-0.1-0.5-0.2-0.8-0.3s-0.6-0.1-0.9-0.1c-0.9,0-1.5,0.3-2.1,1s-0.8,1.6-0.8,2.7c0,1.2,0.3,2.1,0.8,2.7
		s1.2,1,2.1,1c0.5,0,1.1-0.1,1.5-0.3s0.9-0.5,1.3-0.9h0.1v1.9c-0.2,0.1-0.4,0.2-0.6,0.3s-0.5,0.2-0.7,0.2c-0.3,0.1-0.5,0.1-0.8,0.2
		S522.6,282,522.2,282z"
      />
      <path
        className="cls-9"
        d="M529.6,282c-0.9,0-1.6-0.3-2.1-0.8s-0.8-1.3-0.8-2.4v-5.4h-1.1V272h1.1v-2.8h1.7v2.8h3v1.4h-3v4.7
		c0,0.5,0,0.9,0,1.2s0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5s0.5,0.2,0.9,0.2c0.3,0,0.5,0,0.8-0.1s0.4-0.1,0.5-0.2h0.1v1.5
		c-0.3,0.1-0.6,0.2-0.9,0.2S529.9,282,529.6,282z"
      />
      <path
        className="cls-9"
        d="M534.4,270.3h-1.9v-1.7h1.9V270.3z M534.3,281.8h-1.7V272h1.7V281.8z"
      />
      <path
        className="cls-9"
        d="M544.8,276.9c0,1.7-0.4,2.9-1.2,3.8s-1.9,1.3-3.2,1.3c-1.4,0-2.4-0.5-3.2-1.4s-1.2-2.2-1.2-3.8
		c0-1.6,0.4-2.9,1.2-3.8s1.9-1.4,3.2-1.4c1.3,0,2.4,0.5,3.2,1.4S544.8,275.2,544.8,276.9z M543.1,276.9c0-1.3-0.2-2.2-0.7-2.8
		s-1.1-0.9-2-0.9c-0.9,0-1.5,0.3-2,0.9s-0.7,1.6-0.7,2.8c0,1.2,0.2,2.2,0.7,2.8s1.1,1,2,1c0.8,0,1.5-0.3,2-0.9
		S543.1,278.1,543.1,276.9z"
      />
      <path
        className="cls-9"
        d="M554.3,281.8h-1.7v-5.6c0-0.5,0-0.9-0.1-1.3s-0.1-0.7-0.3-0.9c-0.1-0.2-0.3-0.4-0.6-0.5s-0.6-0.2-1-0.2
		c-0.4,0-0.8,0.1-1.3,0.3s-0.9,0.5-1.3,0.9v7.3h-1.7V272h1.7v1.1c0.5-0.4,1-0.8,1.5-1s1-0.4,1.5-0.4c1,0,1.7,0.3,2.3,1
		s0.8,1.6,0.8,2.8V281.8z"
      />
      <path
        className="cls-9"
        d="M570.3,276.9c0,1.7-0.4,2.9-1.2,3.8s-1.9,1.3-3.2,1.3c-1.4,0-2.4-0.5-3.2-1.4s-1.2-2.2-1.2-3.8
		c0-1.6,0.4-2.9,1.2-3.8s1.9-1.4,3.2-1.4c1.3,0,2.4,0.5,3.2,1.4S570.3,275.2,570.3,276.9z M568.6,276.9c0-1.3-0.2-2.2-0.7-2.8
		s-1.1-0.9-2-0.9c-0.9,0-1.5,0.3-2,0.9s-0.7,1.6-0.7,2.8c0,1.2,0.2,2.2,0.7,2.8s1.1,1,2,1c0.8,0,1.5-0.3,2-0.9
		S568.6,278.1,568.6,276.9z"
      />
      <path
        className="cls-9"
        d="M577.1,269.7L577.1,269.7c-0.2-0.1-0.4-0.1-0.7-0.2s-0.5-0.1-0.7-0.1c-0.7,0-1.2,0.2-1.5,0.5s-0.4,0.9-0.4,1.7
		v0.3h2.7v1.4h-2.7v8.4h-1.7v-8.4H571V272h1.1v-0.3c0-1.2,0.3-2.1,0.9-2.7s1.4-0.9,2.4-0.9c0.3,0,0.6,0,0.9,0s0.6,0.1,0.8,0.1V269.7
		z"
      />
      <path
        className="cls-9"
        d="M587.6,282c-1.6,0-2.8-0.4-3.6-1.3s-1.3-2.1-1.3-3.8c0-1.6,0.4-2.9,1.3-3.8s1.9-1.4,3.3-1.4
		c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8s0.6,0.8,0.8,1.4s0.3,1.2,0.3,2v0.9h-6.8c0,1.1,0.3,2,0.9,2.6s1.4,0.9,2.4,0.9
		c0.4,0,0.7,0,1.1-0.1s0.7-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.8-0.4s0.4-0.2,0.5-0.4h0.1v1.8c-0.2,0.1-0.4,0.2-0.7,0.3s-0.5,0.2-0.8,0.3
		c-0.3,0.1-0.6,0.2-0.9,0.2S588,282,587.6,282z M589.5,275.8c0-0.4-0.1-0.8-0.2-1.2s-0.2-0.6-0.4-0.8c-0.2-0.2-0.4-0.4-0.7-0.6
		s-0.7-0.2-1.1-0.2c-0.4,0-0.8,0.1-1.1,0.2s-0.6,0.3-0.8,0.6c-0.2,0.3-0.4,0.5-0.6,0.9s-0.2,0.7-0.2,1.1H589.5z"
      />
      <path
        className="cls-9"
        d="M600.4,285.4h-1.7v-4.7c-0.5,0.4-0.9,0.8-1.4,1s-1,0.3-1.5,0.3c-1.1,0-2-0.4-2.7-1.3s-1-2.1-1-3.7
		c0-0.8,0.1-1.6,0.3-2.2s0.5-1.2,0.9-1.6c0.4-0.4,0.8-0.8,1.3-1s1-0.4,1.6-0.4c0.5,0,0.9,0.1,1.3,0.2s0.7,0.3,1.1,0.5l0.1-0.4h1.5
		V285.4z M598.7,279.3v-5.6c-0.4-0.2-0.8-0.3-1.1-0.4s-0.7-0.1-1.1-0.1c-0.9,0-1.5,0.3-2,0.9s-0.7,1.5-0.7,2.7c0,1.1,0.2,2,0.6,2.6
		s1,0.9,1.7,0.9c0.5,0,0.9-0.1,1.4-0.3S598.3,279.6,598.7,279.3z"
      />
      <path
        className="cls-9"
        d="M610.4,281.8h-1.7v-1.1c-0.5,0.4-1,0.8-1.5,1s-1,0.3-1.5,0.3c-0.4,0-0.8-0.1-1.2-0.2s-0.7-0.4-1-0.7
		s-0.5-0.7-0.7-1.2s-0.2-1-0.2-1.7V272h1.7v5.6c0,0.5,0,1,0.1,1.3s0.1,0.6,0.3,0.9c0.1,0.3,0.3,0.4,0.5,0.6s0.6,0.2,1,0.2
		c0.4,0,0.8-0.1,1.3-0.3s0.9-0.5,1.3-0.8V272h1.7V281.8z"
      />
      <path
        className="cls-9"
        d="M614.6,270.3h-1.9v-1.7h1.9V270.3z M614.5,281.8h-1.7V272h1.7V281.8z"
      />
      <path
        className="cls-9"
        d="M625.1,276.6c0,1.6-0.4,2.9-1.2,3.9s-1.8,1.5-3,1.5c-0.5,0-0.9-0.1-1.3-0.2s-0.8-0.3-1.2-0.5v4.1h-1.7V272h1.7
		v1c0.4-0.4,0.9-0.7,1.4-0.9s1-0.4,1.6-0.4c1.1,0,2,0.4,2.6,1.3S625.1,275.1,625.1,276.6z M623.4,276.8c0-1.1-0.2-2-0.5-2.6
		s-0.9-0.9-1.7-0.9c-0.5,0-0.9,0.1-1.4,0.3s-0.9,0.5-1.2,0.8v5.6c0.4,0.2,0.8,0.4,1.1,0.4s0.7,0.1,1.1,0.1c0.9,0,1.5-0.3,2-0.9
		S623.4,278,623.4,276.8z"
      />
      <path
        className="cls-9"
        d="M638,281.8v-5.7c0-0.5,0-0.9-0.1-1.3s-0.1-0.7-0.2-0.9c-0.1-0.2-0.3-0.4-0.5-0.5s-0.5-0.2-0.9-0.2
		c-0.3,0-0.7,0.1-1.1,0.3s-0.8,0.5-1.2,0.9c0,0.1,0,0.2,0,0.4s0,0.3,0,0.5v6.5h-1.7v-5.7c0-0.5,0-0.9-0.1-1.3s-0.1-0.7-0.2-0.9
		c-0.1-0.2-0.3-0.4-0.5-0.5s-0.5-0.2-0.9-0.2c-0.4,0-0.7,0.1-1.1,0.3s-0.8,0.5-1.1,0.9v7.3h-1.7V272h1.7v1.1c0.4-0.4,0.9-0.8,1.3-1
		s0.9-0.4,1.4-0.4c0.6,0,1.1,0.1,1.5,0.4s0.8,0.7,1,1.3c0.5-0.6,1-1,1.5-1.3s1-0.4,1.5-0.4c0.4,0,0.8,0.1,1.2,0.2s0.6,0.3,0.9,0.6
		c0.3,0.3,0.5,0.7,0.6,1.1s0.2,1,0.2,1.7v6.5H638z"
      />
      <path
        className="cls-9"
        d="M646.2,282c-1.6,0-2.8-0.4-3.6-1.3s-1.3-2.1-1.3-3.8c0-1.6,0.4-2.9,1.3-3.8s1.9-1.4,3.3-1.4
		c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8s0.6,0.8,0.8,1.4s0.3,1.2,0.3,2v0.9H643c0,1.1,0.3,2,0.9,2.6s1.4,0.9,2.4,0.9
		c0.4,0,0.7,0,1.1-0.1s0.7-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.8-0.4s0.4-0.2,0.5-0.4h0.1v1.8c-0.2,0.1-0.4,0.2-0.7,0.3s-0.5,0.2-0.8,0.3
		c-0.3,0.1-0.6,0.2-0.9,0.2S646.6,282,646.2,282z M648.2,275.8c0-0.4-0.1-0.8-0.2-1.2s-0.2-0.6-0.4-0.8c-0.2-0.2-0.4-0.4-0.7-0.6
		s-0.7-0.2-1.1-0.2c-0.4,0-0.8,0.1-1.1,0.2s-0.6,0.3-0.8,0.6c-0.2,0.3-0.4,0.5-0.6,0.9s-0.2,0.7-0.2,1.1H648.2z"
      />
      <path
        className="cls-9"
        d="M659.2,281.8h-1.7v-5.6c0-0.5,0-0.9-0.1-1.3s-0.1-0.7-0.2-0.9c-0.1-0.2-0.3-0.4-0.6-0.5s-0.6-0.2-1-0.2
		c-0.4,0-0.8,0.1-1.3,0.3s-0.9,0.5-1.3,0.9v7.3h-1.7V272h1.7v1.1c0.5-0.4,1-0.8,1.5-1s1-0.4,1.5-0.4c1,0,1.7,0.3,2.3,1
		s0.8,1.6,0.8,2.8V281.8z"
      />
      <path
        className="cls-9"
        d="M664.4,282c-0.9,0-1.6-0.3-2.1-0.8s-0.8-1.3-0.8-2.4v-5.4h-1.1V272h1.1v-2.8h1.7v2.8h3v1.4h-3v4.7
		c0,0.5,0,0.9,0,1.2s0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5s0.5,0.2,0.9,0.2c0.3,0,0.5,0,0.8-0.1s0.4-0.1,0.5-0.2h0.1v1.5
		c-0.3,0.1-0.6,0.2-0.9,0.2C665,281.9,664.7,282,664.4,282z"
      />
      <path
        className="cls-9"
        d="M488.2,302.3c-0.1,0.1-0.3,0.2-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.2-0.6,0.3-0.8,0.4s-0.7,0.1-1.1,0.1
		c-0.4,0-0.8-0.1-1.2-0.2s-0.7-0.4-1-0.6c-0.3-0.3-0.5-0.6-0.6-1s-0.2-0.8-0.2-1.3c0-0.7,0.1-1.3,0.4-1.7s0.7-0.9,1.4-1.1
		c0.6-0.3,1.2-0.4,2-0.5s1.6-0.2,2.4-0.2v-0.3c0-0.4-0.1-0.7-0.2-0.9s-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.3s-0.6-0.1-0.9-0.1
		c-0.4,0-0.8,0.1-1.3,0.2s-1,0.3-1.5,0.5h-0.1v-1.7c0.3-0.1,0.7-0.2,1.3-0.3s1.1-0.2,1.7-0.2c0.7,0,1.2,0.1,1.7,0.2s0.9,0.3,1.2,0.6
		c0.4,0.3,0.6,0.6,0.8,1s0.3,0.9,0.3,1.6v6.7h-1.6V302.3z M488.2,301v-2.7c-0.4,0-0.9,0.1-1.5,0.1s-1.1,0.1-1.5,0.3
		c-0.4,0.1-0.8,0.3-1.1,0.6s-0.4,0.7-0.4,1.2c0,0.6,0.2,1,0.5,1.3s0.8,0.4,1.4,0.4c0.5,0,1-0.1,1.4-0.3S487.9,301.3,488.2,301z"
      />
      <path
        className="cls-9"
        d="M496.1,303.6c-0.7,0-1.3-0.1-1.9-0.3s-1-0.5-1.5-0.9s-0.7-1-0.9-1.6s-0.3-1.4-0.3-2.3c0-0.9,0.1-1.6,0.3-2.2
		s0.5-1.2,0.9-1.6s0.9-0.8,1.4-1s1.2-0.3,1.9-0.3c0.5,0,1.1,0.1,1.6,0.2s0.9,0.3,1.3,0.5v1.9h-0.1c-0.1-0.1-0.3-0.2-0.5-0.3
		s-0.4-0.3-0.7-0.4c-0.2-0.1-0.5-0.2-0.8-0.3s-0.6-0.1-0.9-0.1c-0.9,0-1.5,0.3-2.1,1s-0.8,1.6-0.8,2.7c0,1.2,0.3,2.1,0.8,2.7
		s1.2,1,2.1,1c0.5,0,1.1-0.1,1.5-0.3s0.9-0.5,1.3-0.9h0.1v1.9c-0.2,0.1-0.4,0.2-0.6,0.3s-0.5,0.2-0.7,0.2c-0.3,0.1-0.5,0.1-0.8,0.2
		S496.4,303.6,496.1,303.6z"
      />
      <path
        className="cls-9"
        d="M504.3,303.6c-0.7,0-1.3-0.1-1.9-0.3s-1-0.5-1.5-0.9s-0.7-1-0.9-1.6s-0.3-1.4-0.3-2.3c0-0.9,0.1-1.6,0.3-2.2
		s0.5-1.2,0.9-1.6s0.9-0.8,1.4-1s1.2-0.3,1.9-0.3c0.5,0,1.1,0.1,1.6,0.2s0.9,0.3,1.3,0.5v1.9h-0.1c-0.1-0.1-0.3-0.2-0.5-0.3
		s-0.4-0.3-0.7-0.4c-0.2-0.1-0.5-0.2-0.8-0.3s-0.6-0.1-0.9-0.1c-0.9,0-1.5,0.3-2.1,1s-0.8,1.6-0.8,2.7c0,1.2,0.3,2.1,0.8,2.7
		s1.2,1,2.1,1c0.5,0,1.1-0.1,1.5-0.3s0.9-0.5,1.3-0.9h0.1v1.9c-0.2,0.1-0.4,0.2-0.6,0.3s-0.5,0.2-0.7,0.2c-0.3,0.1-0.5,0.1-0.8,0.2
		S504.7,303.6,504.3,303.6z"
      />
      <path
        className="cls-9"
        d="M516.8,298.5c0,1.7-0.4,2.9-1.2,3.8s-1.9,1.3-3.2,1.3c-1.4,0-2.4-0.5-3.2-1.4s-1.2-2.2-1.2-3.8
		c0-1.6,0.4-2.9,1.2-3.8s1.9-1.4,3.2-1.4c1.3,0,2.4,0.5,3.2,1.4S516.8,296.8,516.8,298.5z M515.1,298.5c0-1.3-0.2-2.2-0.7-2.8
		s-1.1-0.9-2-0.9c-0.9,0-1.5,0.3-2,0.9s-0.7,1.6-0.7,2.8c0,1.2,0.2,2.2,0.7,2.8s1.1,1,2,1c0.8,0,1.5-0.3,2-0.9
		S515.1,299.7,515.1,298.5z"
      />
      <path
        className="cls-9"
        d="M523.7,295.3L523.7,295.3c-0.2,0-0.3-0.1-0.5-0.1s-0.3,0-0.5,0c-0.4,0-0.9,0.1-1.3,0.3s-0.9,0.5-1.2,0.9v7
		h-1.7v-9.8h1.7v1.5c0.6-0.5,1.1-0.9,1.6-1.1s0.9-0.3,1.3-0.3c0.2,0,0.3,0,0.4,0s0.2,0,0.3,0V295.3z"
      />
      <path
        className="cls-9"
        d="M532.5,303.4h-1.7v-1c-0.3,0.2-0.5,0.4-0.7,0.5s-0.4,0.3-0.7,0.4c-0.2,0.1-0.5,0.2-0.7,0.3s-0.5,0.1-0.9,0.1
		c-0.5,0-1-0.1-1.5-0.3s-0.8-0.5-1.2-1c-0.3-0.4-0.6-1-0.7-1.6s-0.3-1.4-0.3-2.2c0-0.9,0.1-1.6,0.3-2.2s0.5-1.2,0.9-1.6
		c0.4-0.4,0.8-0.8,1.3-1s1-0.4,1.6-0.4c0.5,0,0.9,0.1,1.3,0.2s0.7,0.3,1.2,0.5v-4.3h1.7V303.4z M530.8,301v-5.7
		c-0.4-0.2-0.8-0.3-1.1-0.4s-0.7-0.1-1.1-0.1c-0.8,0-1.5,0.3-2,1s-0.7,1.6-0.7,2.8c0,1.2,0.2,2,0.6,2.6s1,0.9,1.8,0.9
		c0.4,0,0.9-0.1,1.3-0.3S530.4,301.3,530.8,301z"
      />
      <path
        className="cls-9"
        d="M536.7,291.9h-1.9v-1.7h1.9V291.9z M536.6,303.4h-1.7v-9.8h1.7V303.4z"
      />
      <path
        className="cls-9"
        d="M546.7,303.4H545v-5.6c0-0.5,0-0.9-0.1-1.3s-0.1-0.7-0.3-0.9c-0.1-0.2-0.3-0.4-0.6-0.5s-0.6-0.2-1-0.2
		c-0.4,0-0.8,0.1-1.3,0.3s-0.9,0.5-1.3,0.9v7.3H539v-9.8h1.7v1.1c0.5-0.4,1-0.8,1.5-1s1-0.4,1.5-0.4c1,0,1.7,0.3,2.3,1
		s0.8,1.6,0.8,2.8V303.4z"
      />

      <path
        className="cls-9"
        d="M720.5,136c-0.9,0-1.8-0.1-2.4-0.3s-1.3-0.4-1.9-0.7v-2.2h0.1c0.6,0.5,1.2,1,2,1.3s1.4,0.4,2.1,0.4
		c1,0,1.7-0.2,2.2-0.6s0.7-0.9,0.7-1.6c0-0.5-0.1-0.9-0.4-1.3s-0.7-0.6-1.2-0.7c-0.4-0.1-0.8-0.2-1.1-0.3s-0.8-0.2-1.3-0.3
		c-0.5-0.1-0.9-0.3-1.2-0.4s-0.7-0.4-0.9-0.7c-0.3-0.3-0.5-0.6-0.6-1c-0.2-0.4-0.2-0.8-0.2-1.3c0-1.1,0.4-1.9,1.2-2.7
		s1.9-1.1,3.2-1.1c0.7,0,1.4,0.1,2.1,0.2c0.7,0.1,1.3,0.4,1.8,0.6v2.1h-0.1c-0.4-0.4-0.9-0.7-1.6-1s-1.3-0.4-2-0.4
		c-0.8,0-1.5,0.2-2,0.6s-0.7,0.9-0.7,1.5c0,0.6,0.1,1,0.4,1.3s0.7,0.6,1.3,0.7c0.4,0.1,0.8,0.2,1.4,0.3s1,0.2,1.3,0.3
		c0.9,0.3,1.6,0.7,2,1.2s0.6,1.2,0.6,2.1c0,0.5-0.1,1-0.3,1.5s-0.5,0.9-0.9,1.3c-0.4,0.4-0.9,0.7-1.4,0.9S721.3,136,720.5,136z"
      />
      <path
        className="cls-9"
        d="M734.4,135.8h-1.7v-1.1c-0.5,0.4-1,0.8-1.5,1s-1,0.3-1.5,0.3c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.1-0.7-0.4-1-0.7
        s-0.5-0.7-0.7-1.2s-0.2-1-0.2-1.7v-6.4h1.7v5.6c0,0.5,0,1,0.1,1.3s0.1,0.6,0.3,0.9c0.1,0.3,0.3,0.4,0.5,0.6s0.6,0.2,1,0.2
        c0.4,0,0.8-0.1,1.3-0.3s0.9-0.5,1.3-0.8v-7.3h1.7V135.8z"
      />
      <path
        className="cls-9"
        d="M745,130.6c0,1.6-0.4,2.9-1.2,3.9c-0.8,1-1.8,1.5-3,1.5c-0.5,0-0.9-0.1-1.3-0.2s-0.8-0.3-1.2-0.5v4.1h-1.7
		v-13.4h1.7v1c0.4-0.4,0.9-0.7,1.4-0.9c0.5-0.2,1-0.4,1.6-0.4c1.1,0,2,0.4,2.6,1.3C744.7,127.9,745,129.1,745,130.6z M743.3,130.8
		c0-1.1-0.2-2-0.5-2.6s-0.9-0.9-1.7-0.9c-0.5,0-0.9,0.1-1.4,0.3c-0.4,0.2-0.9,0.5-1.2,0.8v5.6c0.4,0.2,0.8,0.4,1.1,0.4
		c0.3,0.1,0.7,0.1,1.1,0.1c0.9,0,1.5-0.3,2-0.9S743.3,132,743.3,130.8z"
      />
      <path
        className="cls-9"
        d="M754.9,130.6c0,1.6-0.4,2.9-1.2,3.9c-0.8,1-1.8,1.5-3,1.5c-0.5,0-0.9-0.1-1.3-0.2s-0.8-0.3-1.2-0.5v4.1h-1.7
        v-13.4h1.7v1c0.4-0.4,0.9-0.7,1.4-0.9c0.5-0.2,1-0.4,1.6-0.4c1.1,0,2,0.4,2.6,1.3C754.6,127.9,754.9,129.1,754.9,130.6z
         M753.2,130.8c0-1.1-0.2-2-0.5-2.6s-0.9-0.9-1.7-0.9c-0.5,0-0.9,0.1-1.4,0.3c-0.4,0.2-0.9,0.5-1.2,0.8v5.6c0.4,0.2,0.8,0.4,1.1,0.4
        c0.3,0.1,0.7,0.1,1.1,0.1c0.9,0,1.5-0.3,2-0.9S753.2,132,753.2,130.8z"
      />
      <path className="cls-9" d="M758.3,135.8h-1.7v-13.7h1.7V135.8z" />
      <path
        className="cls-9"
        d="M768.5,125.9l-5.3,13.4h-1.8l1.8-4.1l-3.6-9.3h1.8l2.7,7.3l2.7-7.3H768.5z"
      />
      <path
        className="cls-9"
        d="M783.4,130.8c0,1.7-0.4,2.9-1.2,3.8s-1.9,1.3-3.2,1.3c-1.4,0-2.4-0.5-3.2-1.4c-0.8-0.9-1.2-2.2-1.2-3.8
		c0-1.6,0.4-2.9,1.2-3.8s1.9-1.4,3.2-1.4c1.3,0,2.4,0.5,3.2,1.4S783.4,129.2,783.4,130.8z M781.7,130.8c0-1.3-0.2-2.2-0.7-2.8
		s-1.1-0.9-2-0.9c-0.9,0-1.5,0.3-2,0.9c-0.5,0.6-0.7,1.6-0.7,2.8c0,1.2,0.2,2.2,0.7,2.8c0.5,0.6,1.1,1,2,1c0.8,0,1.5-0.3,2-0.9
		C781.5,133,781.7,132.1,781.7,130.8z"
      />
      <path
        className="cls-9"
        d="M790.3,123.7L790.3,123.7c-0.2-0.1-0.4-0.1-0.7-0.2s-0.5-0.1-0.7-0.1c-0.7,0-1.2,0.2-1.5,0.5s-0.4,0.9-0.4,1.7
		v0.3h2.7v1.4H787v8.4h-1.7v-8.4h-1.1v-1.4h1.1v-0.3c0-1.2,0.3-2.1,0.9-2.7s1.4-0.9,2.4-0.9c0.3,0,0.6,0,0.9,0s0.6,0.1,0.8,0.1
		V123.7z"
      />
      <path
        className="cls-9"
        d="M801.7,127.7L801.7,127.7c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.3,0-0.5,0c-0.4,0-0.9,0.1-1.3,0.3s-0.9,0.5-1.2,0.9
		v7h-1.7v-9.8h1.7v1.5c0.6-0.5,1.1-0.9,1.6-1.1c0.4-0.2,0.9-0.3,1.3-0.3c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0V127.7z"
      />
      <path
        className="cls-9"
        d="M807.1,136c-1.6,0-2.8-0.4-3.6-1.3s-1.3-2.1-1.3-3.8c0-1.6,0.4-2.9,1.3-3.8s1.9-1.4,3.3-1.4
		c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8s0.6,0.8,0.8,1.4s0.3,1.2,0.3,2v0.9h-6.8c0,1.1,0.3,2,0.9,2.6s1.4,0.9,2.4,0.9
		c0.4,0,0.7,0,1.1-0.1s0.7-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.8-0.4s0.4-0.2,0.5-0.4h0.1v1.8c-0.2,0.1-0.4,0.2-0.7,0.3s-0.5,0.2-0.8,0.3
		c-0.3,0.1-0.6,0.2-0.9,0.2C807.9,136,807.5,136,807.1,136z M809.1,129.7c0-0.4-0.1-0.8-0.2-1.2s-0.2-0.6-0.4-0.8
		c-0.2-0.2-0.4-0.4-0.7-0.6s-0.7-0.2-1.1-0.2c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0.3-0.8,0.6c-0.2,0.3-0.4,0.5-0.6,0.9
		s-0.2,0.7-0.2,1.1H809.1z"
      />
      <path
        className="cls-9"
        d="M817.8,134.7c-0.1,0.1-0.3,0.2-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.2-0.6,0.3-0.8,0.4c-0.3,0.1-0.7,0.1-1.1,0.1
		c-0.4,0-0.8-0.1-1.2-0.2s-0.7-0.4-1-0.6c-0.3-0.3-0.5-0.6-0.6-1s-0.2-0.8-0.2-1.3c0-0.7,0.1-1.3,0.4-1.7s0.7-0.9,1.4-1.1
		c0.6-0.3,1.2-0.4,2-0.5s1.6-0.2,2.4-0.2V129c0-0.4-0.1-0.7-0.2-0.9s-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.3s-0.6-0.1-0.9-0.1
		c-0.4,0-0.8,0.1-1.3,0.2s-1,0.3-1.5,0.5h-0.1v-1.7c0.3-0.1,0.7-0.2,1.3-0.3s1.1-0.2,1.7-0.2c0.7,0,1.2,0.1,1.7,0.2s0.9,0.3,1.2,0.6
		c0.4,0.3,0.6,0.6,0.8,1s0.3,0.9,0.3,1.6v6.7h-1.6V134.7z M817.8,133.3v-2.7c-0.4,0-0.9,0.1-1.5,0.1s-1.1,0.1-1.5,0.3
		c-0.4,0.1-0.8,0.3-1.1,0.6s-0.4,0.7-0.4,1.2c0,0.6,0.2,1,0.5,1.3s0.8,0.4,1.4,0.4c0.5,0,1-0.1,1.4-0.3S817.5,133.7,817.8,133.3z"
      />
      <path
        className="cls-9"
        d="M829.4,135.8h-1.7v-1c-0.3,0.2-0.5,0.4-0.7,0.5s-0.4,0.3-0.7,0.4c-0.2,0.1-0.5,0.2-0.7,0.3s-0.5,0.1-0.9,0.1
		c-0.5,0-1-0.1-1.5-0.3c-0.5-0.2-0.8-0.5-1.2-1c-0.3-0.4-0.6-1-0.7-1.6s-0.3-1.4-0.3-2.2c0-0.9,0.1-1.6,0.3-2.2s0.5-1.2,0.9-1.6
		c0.4-0.4,0.8-0.8,1.3-1c0.5-0.2,1-0.4,1.6-0.4c0.5,0,0.9,0.1,1.3,0.2s0.7,0.3,1.2,0.5v-4.3h1.7V135.8z M827.7,133.4v-5.7
		c-0.4-0.2-0.8-0.3-1.1-0.4c-0.3-0.1-0.7-0.1-1.1-0.1c-0.8,0-1.5,0.3-2,1s-0.7,1.6-0.7,2.8c0,1.2,0.2,2,0.6,2.6s1,0.9,1.8,0.9
		c0.4,0,0.9-0.1,1.3-0.3S827.3,133.7,827.7,133.4z"
      />
      <path
        className="cls-9"
        d="M839.5,125.9l-5.3,13.4h-1.8l1.8-4.1l-3.6-9.3h1.8l2.7,7.3l2.7-7.3H839.5z"
      />
      <path className="cls-9" d="M844.9,130.8h-5v-1.6h5V130.8z" />
      <path
        className="cls-9"
        d="M858.1,135.8v-5.7c0-0.5,0-0.9-0.1-1.3s-0.1-0.7-0.2-0.9c-0.1-0.2-0.3-0.4-0.5-0.5s-0.5-0.2-0.9-0.2
		c-0.3,0-0.7,0.1-1.1,0.3c-0.4,0.2-0.8,0.5-1.2,0.9c0,0.1,0,0.2,0,0.4c0,0.1,0,0.3,0,0.5v6.5h-1.7v-5.7c0-0.5,0-0.9-0.1-1.3
		s-0.1-0.7-0.2-0.9c-0.1-0.2-0.3-0.4-0.5-0.5s-0.5-0.2-0.9-0.2c-0.4,0-0.7,0.1-1.1,0.3s-0.8,0.5-1.1,0.9v7.3h-1.7v-9.8h1.7v1.1
		c0.4-0.4,0.9-0.8,1.3-1s0.9-0.4,1.4-0.4c0.6,0,1.1,0.1,1.5,0.4c0.4,0.3,0.8,0.7,1,1.3c0.5-0.6,1-1,1.5-1.3s1-0.4,1.5-0.4
		c0.4,0,0.8,0.1,1.2,0.2s0.6,0.3,0.9,0.6c0.3,0.3,0.5,0.7,0.6,1.1c0.1,0.4,0.2,1,0.2,1.7v6.5H858.1z"
      />
      <path
        className="cls-9"
        d="M867.5,134.7c-0.1,0.1-0.3,0.2-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.2-0.6,0.3-0.8,0.4c-0.3,0.1-0.7,0.1-1.1,0.1
		c-0.4,0-0.8-0.1-1.2-0.2s-0.7-0.4-1-0.6c-0.3-0.3-0.5-0.6-0.6-1s-0.2-0.8-0.2-1.3c0-0.7,0.1-1.3,0.4-1.7s0.7-0.9,1.4-1.1
		c0.6-0.3,1.2-0.4,2-0.5s1.6-0.2,2.4-0.2V129c0-0.4-0.1-0.7-0.2-0.9s-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.3s-0.6-0.1-0.9-0.1
		c-0.4,0-0.8,0.1-1.3,0.2s-1,0.3-1.5,0.5h-0.1v-1.7c0.3-0.1,0.7-0.2,1.3-0.3s1.1-0.2,1.7-0.2c0.7,0,1.2,0.1,1.7,0.2s0.9,0.3,1.2,0.6
		c0.4,0.3,0.6,0.6,0.8,1s0.3,0.9,0.3,1.6v6.7h-1.6V134.7z M867.5,133.3v-2.7c-0.4,0-0.9,0.1-1.5,0.1s-1.1,0.1-1.5,0.3
		c-0.4,0.1-0.8,0.3-1.1,0.6s-0.4,0.7-0.4,1.2c0,0.6,0.2,1,0.5,1.3s0.8,0.4,1.4,0.4c0.5,0,1-0.1,1.4-0.3S867.1,133.7,867.5,133.3z"
      />
      <path
        className="cls-9"
        d="M879,135.8h-1.7v-1c-0.3,0.2-0.5,0.4-0.7,0.5s-0.4,0.3-0.7,0.4c-0.2,0.1-0.5,0.2-0.7,0.3s-0.5,0.1-0.9,0.1
		c-0.5,0-1-0.1-1.5-0.3c-0.5-0.2-0.8-0.5-1.2-1c-0.3-0.4-0.6-1-0.7-1.6s-0.3-1.4-0.3-2.2c0-0.9,0.1-1.6,0.3-2.2s0.5-1.2,0.9-1.6
		c0.4-0.4,0.8-0.8,1.3-1c0.5-0.2,1-0.4,1.6-0.4c0.5,0,0.9,0.1,1.3,0.2s0.7,0.3,1.2,0.5v-4.3h1.7V135.8z M877.4,133.4v-5.7
		c-0.4-0.2-0.8-0.3-1.1-0.4c-0.3-0.1-0.7-0.1-1.1-0.1c-0.8,0-1.5,0.3-2,1s-0.7,1.6-0.7,2.8c0,1.2,0.2,2,0.6,2.6s1,0.9,1.8,0.9
		c0.4,0,0.9-0.1,1.3-0.3S877,133.7,877.4,133.4z"
      />
      <path
        className="cls-9"
        d="M885.7,136c-1.6,0-2.8-0.4-3.6-1.3s-1.3-2.1-1.3-3.8c0-1.6,0.4-2.9,1.3-3.8s1.9-1.4,3.3-1.4
		c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8s0.6,0.8,0.8,1.4s0.3,1.2,0.3,2v0.9h-6.8c0,1.1,0.3,2,0.9,2.6s1.4,0.9,2.4,0.9
		c0.4,0,0.7,0,1.1-0.1s0.7-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.8-0.4s0.4-0.2,0.5-0.4h0.1v1.8c-0.2,0.1-0.4,0.2-0.7,0.3s-0.5,0.2-0.8,0.3
		c-0.3,0.1-0.6,0.2-0.9,0.2C886.4,136,886.1,136,885.7,136z M887.6,129.7c0-0.4-0.1-0.8-0.2-1.2s-0.2-0.6-0.4-0.8
		c-0.2-0.2-0.4-0.4-0.7-0.6s-0.7-0.2-1.1-0.2c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0.3-0.8,0.6c-0.2,0.3-0.4,0.5-0.6,0.9
		s-0.2,0.7-0.2,1.1H887.6z"
      />
      <path
        className="cls-9"
        d="M710.1,157.5c-0.9,0-1.6-0.3-2.1-0.8s-0.8-1.3-0.8-2.4v-5.4h-1.1v-1.4h1.1v-2.8h1.7v2.8h3v1.4h-3v4.7
        c0,0.5,0,0.9,0,1.2s0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5s0.5,0.2,0.9,0.2c0.3,0,0.5,0,0.8-0.1s0.4-0.1,0.5-0.2h0.1v1.5
        c-0.3,0.1-0.6,0.2-0.9,0.2S710.4,157.5,710.1,157.5z"
      />
      <path
        className="cls-9"
        d="M717.4,157.6c-1.6,0-2.8-0.4-3.6-1.3s-1.3-2.1-1.3-3.8c0-1.6,0.4-2.9,1.3-3.8s1.9-1.4,3.3-1.4
		c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8s0.6,0.8,0.8,1.4s0.3,1.2,0.3,2v0.9h-6.8c0,1.1,0.3,2,0.9,2.6s1.4,0.9,2.4,0.9
		c0.4,0,0.7,0,1.1-0.1s0.7-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.8-0.4s0.4-0.2,0.5-0.4h0.1v1.8c-0.2,0.1-0.4,0.2-0.7,0.3s-0.5,0.2-0.8,0.3
		c-0.3,0.1-0.6,0.2-0.9,0.2C718.2,157.6,717.8,157.6,717.4,157.6z M719.4,151.4c0-0.4-0.1-0.8-0.2-1.2s-0.2-0.6-0.4-0.8
		c-0.2-0.2-0.4-0.4-0.7-0.6s-0.7-0.2-1.1-0.2c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0.3-0.8,0.6c-0.2,0.3-0.4,0.5-0.6,0.9
		s-0.2,0.7-0.2,1.1H719.4z"
      />
      <path
        className="cls-9"
        d="M726.6,157.6c-0.7,0-1.3-0.1-1.9-0.3s-1-0.5-1.5-0.9s-0.7-1-0.9-1.6s-0.3-1.4-0.3-2.3c0-0.9,0.1-1.6,0.3-2.2
        s0.5-1.2,0.9-1.6s0.9-0.8,1.4-1s1.2-0.3,1.9-0.3c0.5,0,1.1,0.1,1.6,0.2c0.5,0.2,0.9,0.3,1.3,0.5v1.9h-0.1c-0.1-0.1-0.3-0.2-0.5-0.3
        c-0.2-0.1-0.4-0.3-0.7-0.4c-0.2-0.1-0.5-0.2-0.8-0.3s-0.6-0.1-0.9-0.1c-0.9,0-1.5,0.3-2.1,1c-0.5,0.6-0.8,1.6-0.8,2.7
        c0,1.2,0.2,2.1,0.8,2.7c0.5,0.6,1.2,1,2.1,1c0.5,0,1.1-0.1,1.5-0.3s0.9-0.5,1.3-0.9h0.1v1.9c-0.2,0.1-0.4,0.2-0.6,0.3
        s-0.5,0.2-0.7,0.2c-0.3,0.1-0.5,0.1-0.8,0.2S726.9,157.6,726.6,157.6z"
      />
      <path
        className="cls-9"
        d="M738.7,157.4H737v-5.6c0-0.5,0-0.9-0.1-1.3c0-0.4-0.1-0.7-0.2-0.9c-0.1-0.2-0.3-0.4-0.6-0.5s-0.6-0.2-1-0.2
        c-0.4,0-0.8,0.1-1.3,0.3c-0.4,0.2-0.9,0.5-1.3,0.9v7.3H731v-13.7h1.7v4.9c0.5-0.4,1-0.8,1.5-1s1-0.4,1.5-0.4c1,0,1.7,0.3,2.3,1
        s0.8,1.6,0.8,2.8V157.4z"
      />
      <path
        className="cls-9"
        d="M748.7,157.4h-1.7v-5.6c0-0.5,0-0.9-0.1-1.3c0-0.4-0.1-0.7-0.2-0.9c-0.1-0.2-0.3-0.4-0.6-0.5s-0.6-0.2-1-0.2
        c-0.4,0-0.8,0.1-1.3,0.3c-0.4,0.2-0.9,0.5-1.3,0.9v7.3H741v-9.8h1.7v1.1c0.5-0.4,1-0.8,1.5-1s1-0.4,1.5-0.4c1,0,1.7,0.3,2.3,1
        s0.8,1.6,0.8,2.8V157.4z"
      />
      <path
        className="cls-9"
        d="M759.1,152.4c0,1.7-0.4,2.9-1.2,3.8s-1.9,1.3-3.2,1.3c-1.4,0-2.4-0.5-3.2-1.4c-0.8-0.9-1.2-2.2-1.2-3.8
		c0-1.6,0.4-2.9,1.2-3.8s1.9-1.4,3.2-1.4c1.3,0,2.4,0.5,3.2,1.4S759.1,150.8,759.1,152.4z M757.4,152.4c0-1.3-0.2-2.2-0.7-2.8
		s-1.1-0.9-2-0.9c-0.9,0-1.5,0.3-2,0.9c-0.5,0.6-0.7,1.6-0.7,2.8c0,1.2,0.2,2.2,0.7,2.8c0.5,0.6,1.1,1,2,1c0.8,0,1.5-0.3,2-0.9
		C757.2,154.6,757.4,153.7,757.4,152.4z"
      />
      <path className="cls-9" d="M762.5,157.4h-1.7v-13.7h1.7V157.4z" />
      <path
        className="cls-9"
        d="M773,152.4c0,1.7-0.4,2.9-1.2,3.8s-1.9,1.3-3.2,1.3c-1.4,0-2.4-0.5-3.2-1.4c-0.8-0.9-1.2-2.2-1.2-3.8
		c0-1.6,0.4-2.9,1.2-3.8s1.9-1.4,3.2-1.4c1.3,0,2.4,0.5,3.2,1.4S773,150.8,773,152.4z M771.3,152.4c0-1.3-0.2-2.2-0.7-2.8
		s-1.1-0.9-2-0.9c-0.9,0-1.5,0.3-2,0.9c-0.5,0.6-0.7,1.6-0.7,2.8c0,1.2,0.2,2.2,0.7,2.8c0.5,0.6,1.1,1,2,1c0.8,0,1.5-0.3,2-0.9
		C771.1,154.6,771.3,153.7,771.3,152.4z"
      />
      <path
        className="cls-9"
        d="M775,158.9c0.1,0,0.3,0.1,0.5,0.2s0.5,0.2,0.7,0.2c0.3,0.1,0.6,0.1,0.8,0.2s0.5,0.1,0.8,0.1
		c0.5,0,0.9-0.1,1.3-0.2c0.4-0.1,0.6-0.3,0.8-0.6c0.2-0.2,0.3-0.5,0.4-0.8s0.1-0.7,0.1-1.1v-0.9c-0.4,0.4-0.9,0.7-1.3,0.9
		s-1,0.3-1.6,0.3c-1.1,0-2-0.4-2.7-1.2s-1-2-1-3.6c0-0.8,0.1-1.5,0.3-2.2s0.5-1.2,0.9-1.6c0.4-0.4,0.8-0.7,1.3-1s1-0.3,1.6-0.3
		c0.5,0,1,0.1,1.3,0.2s0.7,0.3,1.1,0.5l0.1-0.4h1.6v8.7c0,1.7-0.4,2.9-1.1,3.7s-1.8,1.2-3.3,1.2c-0.5,0-1,0-1.5-0.1
		c-0.5-0.1-1-0.2-1.4-0.3L775,158.9L775,158.9z M780.6,154.7v-5.4c-0.4-0.2-0.8-0.3-1.1-0.4s-0.7-0.1-1-0.1c-0.8,0-1.5,0.3-2,0.9
		s-0.7,1.5-0.7,2.6c0,1.1,0.2,1.9,0.5,2.5c0.4,0.6,1,0.9,1.8,0.9c0.4,0,0.9-0.1,1.3-0.3S780.2,155,780.6,154.7z"
      />
      <path
        className="cls-9"
        d="M786.4,145.9h-1.9v-1.7h1.9V145.9z M786.3,157.4h-1.7v-9.8h1.7V157.4z"
      />
      <path
        className="cls-9"
        d="M792.7,157.6c-0.7,0-1.3-0.1-1.9-0.3s-1-0.5-1.5-0.9s-0.7-1-0.9-1.6s-0.3-1.4-0.3-2.3c0-0.9,0.1-1.6,0.3-2.2
		s0.5-1.2,0.9-1.6s0.9-0.8,1.4-1s1.2-0.3,1.9-0.3c0.5,0,1.1,0.1,1.6,0.2c0.5,0.2,0.9,0.3,1.3,0.5v1.9h-0.1c-0.1-0.1-0.3-0.2-0.5-0.3
		c-0.2-0.1-0.4-0.3-0.7-0.4c-0.2-0.1-0.5-0.2-0.8-0.3s-0.6-0.1-0.9-0.1c-0.9,0-1.5,0.3-2.1,1c-0.5,0.6-0.8,1.6-0.8,2.7
		c0,1.2,0.2,2.1,0.8,2.7c0.5,0.6,1.2,1,2.1,1c0.5,0,1.1-0.1,1.5-0.3s0.9-0.5,1.3-0.9h0.1v1.9c-0.2,0.1-0.4,0.2-0.6,0.3
		s-0.5,0.2-0.7,0.2c-0.3,0.1-0.5,0.1-0.8,0.2S793,157.6,792.7,157.6z"
      />
      <path
        className="cls-9"
        d="M802.5,156.3c-0.1,0.1-0.3,0.2-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.2-0.6,0.3-0.8,0.4c-0.3,0.1-0.7,0.1-1.1,0.1
		c-0.4,0-0.8-0.1-1.2-0.2s-0.7-0.4-1-0.6c-0.3-0.3-0.5-0.6-0.6-1s-0.2-0.8-0.2-1.3c0-0.7,0.1-1.3,0.4-1.7s0.7-0.9,1.4-1.1
		c0.6-0.3,1.2-0.4,2-0.5s1.6-0.2,2.4-0.2v-0.3c0-0.4-0.1-0.7-0.2-0.9s-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.3s-0.6-0.1-0.9-0.1
		c-0.4,0-0.8,0.1-1.3,0.2s-1,0.3-1.5,0.5h-0.1v-1.7c0.3-0.1,0.7-0.2,1.3-0.3s1.1-0.2,1.7-0.2c0.7,0,1.2,0.1,1.7,0.2s0.9,0.3,1.2,0.6
		c0.4,0.3,0.6,0.6,0.8,1s0.3,0.9,0.3,1.6v6.7h-1.6V156.3z M802.5,154.9v-2.7c-0.4,0-0.9,0.1-1.5,0.1s-1.1,0.1-1.5,0.3
		c-0.4,0.1-0.8,0.3-1.1,0.6s-0.4,0.7-0.4,1.2c0,0.6,0.2,1,0.5,1.3s0.8,0.4,1.4,0.4c0.5,0,1-0.1,1.4-0.3S802.2,155.3,802.5,154.9z"
      />
      <path className="cls-9" d="M808.2,157.4h-1.7v-13.7h1.7V157.4z" />
      <path
        className="cls-9"
        d="M820.5,157.6c-1.6,0-2.8-0.4-3.6-1.3s-1.3-2.1-1.3-3.8c0-1.6,0.4-2.9,1.3-3.8s1.9-1.4,3.3-1.4
		c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8s0.6,0.8,0.8,1.4s0.3,1.2,0.3,2v0.9h-6.8c0,1.1,0.3,2,0.9,2.6s1.4,0.9,2.4,0.9
		c0.4,0,0.7,0,1.1-0.1s0.7-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.8-0.4s0.4-0.2,0.5-0.4h0.1v1.8c-0.2,0.1-0.4,0.2-0.7,0.3s-0.5,0.2-0.8,0.3
		c-0.3,0.1-0.6,0.2-0.9,0.2C821.2,157.6,820.9,157.6,820.5,157.6z M822.4,151.4c0-0.4-0.1-0.8-0.2-1.2s-0.2-0.6-0.4-0.8
		c-0.2-0.2-0.4-0.4-0.7-0.6s-0.7-0.2-1.1-0.2c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0.3-0.8,0.6c-0.2,0.3-0.4,0.5-0.6,0.9
		s-0.2,0.7-0.2,1.1H822.4z"
      />
      <path
        className="cls-9"
        d="M833.3,161h-1.7v-4.7c-0.5,0.4-0.9,0.8-1.4,1s-1,0.3-1.5,0.3c-1.1,0-2-0.4-2.7-1.3s-1-2.1-1-3.7
		c0-0.8,0.1-1.6,0.3-2.2s0.5-1.2,0.9-1.6c0.4-0.4,0.8-0.8,1.3-1s1-0.4,1.6-0.4c0.5,0,0.9,0.1,1.3,0.2s0.7,0.3,1.1,0.5l0.1-0.4h1.5
		V161z M831.6,154.9v-5.6c-0.4-0.2-0.8-0.3-1.1-0.4s-0.7-0.1-1.1-0.1c-0.9,0-1.5,0.3-2,0.9s-0.7,1.5-0.7,2.7c0,1.1,0.2,2,0.6,2.6
		s1,0.9,1.7,0.9c0.5,0,0.9-0.1,1.4-0.3S831.3,155.2,831.6,154.9z"
      />
      <path
        className="cls-9"
        d="M843.3,157.4h-1.7v-1.1c-0.5,0.4-1,0.8-1.5,1s-1,0.3-1.5,0.3c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.1-0.7-0.4-1-0.7
        s-0.5-0.7-0.7-1.2s-0.2-1-0.2-1.7v-6.4h1.7v5.6c0,0.5,0,1,0.1,1.3s0.1,0.6,0.3,0.9c0.1,0.3,0.3,0.4,0.5,0.6s0.6,0.2,1,0.2
        c0.4,0,0.8-0.1,1.3-0.3s0.9-0.5,1.3-0.8v-7.3h1.7V157.4z"
      />
      <path
        className="cls-9"
        d="M847.5,145.9h-1.9v-1.7h1.9V145.9z M847.4,157.4h-1.7v-9.8h1.7V157.4z"
      />
      <path
        className="cls-9"
        d="M858.1,152.2c0,1.6-0.4,2.9-1.2,3.9c-0.8,1-1.8,1.5-3,1.5c-0.5,0-0.9-0.1-1.3-0.2s-0.8-0.3-1.2-0.5v4.1h-1.7
		v-13.4h1.7v1c0.4-0.4,0.9-0.7,1.4-0.9c0.5-0.2,1-0.4,1.6-0.4c1.1,0,2,0.4,2.6,1.3C857.7,149.5,858.1,150.7,858.1,152.2z
		 M856.4,152.4c0-1.1-0.2-2-0.5-2.6s-0.9-0.9-1.7-0.9c-0.5,0-0.9,0.1-1.4,0.3c-0.4,0.2-0.9,0.5-1.2,0.8v5.6c0.4,0.2,0.8,0.4,1.1,0.4
		c0.3,0.1,0.7,0.1,1.1,0.1c0.9,0,1.5-0.3,2-0.9S856.4,153.6,856.4,152.4z"
      />
      <path
        className="cls-9"
        d="M870.9,157.4v-5.7c0-0.5,0-0.9-0.1-1.3s-0.1-0.7-0.2-0.9c-0.1-0.2-0.3-0.4-0.5-0.5s-0.5-0.2-0.9-0.2
        c-0.3,0-0.7,0.1-1.1,0.3c-0.4,0.2-0.8,0.5-1.2,0.9c0,0.1,0,0.2,0,0.4c0,0.1,0,0.3,0,0.5v6.5h-1.7v-5.7c0-0.5,0-0.9-0.1-1.3
        s-0.1-0.7-0.2-0.9c-0.1-0.2-0.3-0.4-0.5-0.5s-0.5-0.2-0.9-0.2c-0.4,0-0.7,0.1-1.1,0.3s-0.8,0.5-1.1,0.9v7.3h-1.7v-9.8h1.7v1.1
        c0.4-0.4,0.9-0.8,1.3-1s0.9-0.4,1.4-0.4c0.6,0,1.1,0.1,1.5,0.4c0.4,0.3,0.8,0.7,1,1.3c0.5-0.6,1-1,1.5-1.3s1-0.4,1.5-0.4
        c0.4,0,0.8,0.1,1.2,0.2s0.6,0.3,0.9,0.6c0.3,0.3,0.5,0.7,0.6,1.1c0.1,0.4,0.2,1,0.2,1.7v6.5H870.9z"
      />
      <path
        className="cls-9"
        d="M879.2,157.6c-1.6,0-2.8-0.4-3.6-1.3s-1.3-2.1-1.3-3.8c0-1.6,0.4-2.9,1.3-3.8s1.9-1.4,3.3-1.4
        c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8s0.6,0.8,0.8,1.4s0.3,1.2,0.3,2v0.9h-6.8c0,1.1,0.3,2,0.9,2.6s1.4,0.9,2.4,0.9
        c0.4,0,0.7,0,1.1-0.1s0.7-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.8-0.4s0.4-0.2,0.5-0.4h0.1v1.8c-0.2,0.1-0.4,0.2-0.7,0.3s-0.5,0.2-0.8,0.3
        c-0.3,0.1-0.6,0.2-0.9,0.2C879.9,157.6,879.6,157.6,879.2,157.6z M881.1,151.4c0-0.4-0.1-0.8-0.2-1.2s-0.2-0.6-0.4-0.8
        c-0.2-0.2-0.4-0.4-0.7-0.6s-0.7-0.2-1.1-0.2c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0.3-0.8,0.6c-0.2,0.3-0.4,0.5-0.6,0.9
        s-0.2,0.7-0.2,1.1H881.1z"
      />
      <path
        className="cls-9"
        d="M892.1,157.4h-1.7v-5.6c0-0.5,0-0.9-0.1-1.3c0-0.4-0.1-0.7-0.2-0.9c-0.1-0.2-0.3-0.4-0.6-0.5s-0.6-0.2-1-0.2
        c-0.4,0-0.8,0.1-1.3,0.3c-0.4,0.2-0.9,0.5-1.3,0.9v7.3h-1.7v-9.8h1.7v1.1c0.5-0.4,1-0.8,1.5-1s1-0.4,1.5-0.4c1,0,1.7,0.3,2.3,1
        s0.8,1.6,0.8,2.8V157.4z"
      />
      <path
        className="cls-9"
        d="M897.4,157.5c-0.9,0-1.6-0.3-2.1-0.8s-0.8-1.3-0.8-2.4v-5.4h-1.1v-1.4h1.1v-2.8h1.7v2.8h3v1.4h-3v4.7
        c0,0.5,0,0.9,0,1.2s0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5s0.5,0.2,0.9,0.2c0.3,0,0.5,0,0.8-0.1s0.4-0.1,0.5-0.2h0.1v1.5
        c-0.3,0.1-0.6,0.2-0.9,0.2S897.6,157.5,897.4,157.5z"
      />

      <path
        className="cls-9"
        d="M521.2,552.8c0.5,0.6,0.9,1.3,1.2,2.1s0.4,1.8,0.4,2.9c0,1.1-0.1,2-0.4,2.9s-0.7,1.6-1.2,2.2
		c-0.5,0.6-1.2,1.1-1.9,1.3c-0.7,0.3-1.5,0.4-2.4,0.4c-0.9,0-1.7-0.1-2.4-0.4s-1.3-0.7-1.8-1.3c-0.5-0.6-0.9-1.3-1.2-2.1
		s-0.4-1.8-0.4-2.9c0-1.1,0.1-2.1,0.4-2.9s0.7-1.5,1.2-2.1c0.5-0.6,1.1-1,1.8-1.3s1.5-0.5,2.4-0.5c0.9,0,1.7,0.2,2.4,0.5
		S520.7,552.2,521.2,552.8z M520.9,557.8c0-0.9-0.1-1.6-0.3-2.3s-0.5-1.2-0.8-1.7c-0.3-0.5-0.8-0.8-1.3-1s-1-0.3-1.6-0.3
		c-0.6,0-1.2,0.1-1.7,0.3c-0.5,0.2-0.9,0.6-1.3,1c-0.3,0.4-0.6,1-0.8,1.7s-0.3,1.4-0.3,2.3c0,1.7,0.4,3,1.1,3.9
		c0.7,0.9,1.7,1.4,2.9,1.4s2.2-0.5,2.9-1.4C520.6,560.9,520.9,559.6,520.9,557.8z"
      />
      <path
        className="cls-9"
        d="M527.5,564.6c-0.9,0-1.6-0.3-2.1-0.8c-0.5-0.5-0.8-1.3-0.8-2.4v-5.4h-1.1v-1.4h1.1v-2.8h1.7v2.8h3v1.4h-3v4.7
		c0,0.5,0,0.9,0,1.2s0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5s0.5,0.2,0.9,0.2c0.3,0,0.5,0,0.8-0.1c0.3-0.1,0.4-0.1,0.5-0.2h0.1v1.5
		c-0.3,0.1-0.6,0.2-0.9,0.2C528.1,564.5,527.8,564.6,527.5,564.6z"
      />
      <path
        className="cls-9"
        d="M538.3,564.4h-1.7v-5.6c0-0.5,0-0.9-0.1-1.3c0-0.4-0.1-0.7-0.3-0.9c-0.1-0.2-0.3-0.4-0.6-0.5s-0.6-0.2-1-0.2
        c-0.4,0-0.8,0.1-1.3,0.3c-0.4,0.2-0.9,0.5-1.3,0.9v7.3h-1.7v-13.7h1.7v4.9c0.5-0.4,1-0.8,1.5-1s1-0.4,1.5-0.4c1,0,1.7,0.3,2.3,1
        c0.5,0.6,0.8,1.6,0.8,2.8V564.4z"
      />
      <path
        className="cls-9"
        d="M544.9,564.6c-1.6,0-2.8-0.4-3.6-1.3s-1.3-2.1-1.3-3.8c0-1.6,0.4-2.9,1.3-3.8s1.9-1.4,3.3-1.4
		c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8s0.6,0.8,0.8,1.4c0.2,0.5,0.3,1.2,0.3,2v0.9h-6.8c0,1.1,0.3,2,0.9,2.6s1.4,0.9,2.4,0.9
		c0.4,0,0.7,0,1.1-0.1s0.7-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.8-0.4s0.4-0.2,0.5-0.4h0.1v1.8c-0.2,0.1-0.4,0.2-0.7,0.3
		c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.2-0.9,0.2S545.3,564.6,544.9,564.6z M546.8,558.4c0-0.4-0.1-0.8-0.2-1.2
		s-0.2-0.6-0.4-0.8c-0.2-0.2-0.4-0.4-0.7-0.6s-0.7-0.2-1.1-0.2c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0.3-0.8,0.6
		c-0.2,0.3-0.4,0.5-0.6,0.9c-0.1,0.3-0.2,0.7-0.2,1.1H546.8z"
      />
      <path
        className="cls-9"
        d="M555.3,556.3L555.3,556.3c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.3,0-0.5,0c-0.4,0-0.9,0.1-1.3,0.3s-0.9,0.5-1.2,0.9
		v7h-1.7v-9.8h1.7v1.5c0.6-0.5,1.1-0.9,1.6-1.1c0.4-0.2,0.9-0.3,1.3-0.3c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0V556.3z"
      />
      <path
        className="cls-9"
        d="M564.8,564.6c-0.7,0-1.3-0.1-1.8-0.3s-1-0.4-1.4-0.6v-1.9h0.1c0.1,0.1,0.3,0.2,0.5,0.4s0.4,0.3,0.8,0.4
		c0.3,0.1,0.6,0.2,0.9,0.3s0.7,0.1,1.1,0.1c0.3,0,0.5,0,0.8-0.1c0.3-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.4-0.4s0.1-0.4,0.1-0.7
		c0-0.4-0.1-0.7-0.3-0.9s-0.6-0.4-1.1-0.5c-0.2-0.1-0.5-0.1-0.8-0.2s-0.7-0.1-0.9-0.2c-0.8-0.2-1.3-0.6-1.6-1
		c-0.3-0.5-0.5-1-0.5-1.6c0-0.9,0.3-1.6,1-2.2s1.6-0.8,2.7-0.8c0.5,0,1.1,0.1,1.6,0.2s1,0.3,1.4,0.5v1.8h-0.1
		c-0.4-0.3-0.9-0.6-1.4-0.8s-1-0.3-1.6-0.3c-0.5,0-1,0.1-1.4,0.3s-0.6,0.6-0.6,1c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.6,0.4,1.1,0.5
		c0.2,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.6,0.1,1,0.2c0.7,0.2,1.3,0.5,1.6,0.9s0.5,1,0.5,1.7c0,0.4-0.1,0.8-0.3,1.2
		c-0.2,0.4-0.4,0.7-0.7,1c-0.4,0.3-0.7,0.5-1.2,0.7S565.5,564.6,564.8,564.6z"
      />
      <path
        className="cls-9"
        d="M574.5,564.6c-1.6,0-2.8-0.4-3.6-1.3s-1.3-2.1-1.3-3.8c0-1.6,0.4-2.9,1.3-3.8s1.9-1.4,3.3-1.4
		c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8s0.6,0.8,0.8,1.4c0.2,0.5,0.3,1.2,0.3,2v0.9h-6.8c0,1.1,0.3,2,0.9,2.6s1.4,0.9,2.4,0.9
		c0.4,0,0.7,0,1.1-0.1s0.7-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.8-0.4s0.4-0.2,0.5-0.4h0.1v1.8c-0.2,0.1-0.4,0.2-0.7,0.3
		c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.2-0.9,0.2S574.9,564.6,574.5,564.6z M576.4,558.4c0-0.4-0.1-0.8-0.2-1.2
		s-0.2-0.6-0.4-0.8c-0.2-0.2-0.4-0.4-0.7-0.6s-0.7-0.2-1.1-0.2c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0.3-0.8,0.6
		c-0.2,0.3-0.4,0.5-0.6,0.9c-0.1,0.3-0.2,0.7-0.2,1.1H576.4z"
      />
      <path
        className="cls-9"
        d="M584.9,556.3L584.9,556.3c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.3,0-0.5,0c-0.4,0-0.9,0.1-1.3,0.3s-0.9,0.5-1.2,0.9
		v7h-1.7v-9.8h1.7v1.5c0.6-0.5,1.1-0.9,1.6-1.1c0.4-0.2,0.9-0.3,1.3-0.3c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0V556.3z"
      />
      <path
        className="cls-9"
        d="M593.9,554.6l-3.7,9.8h-1.5l-3.7-9.8h1.8l2.7,7.6l2.7-7.6H593.9z"
      />
      <path
        className="cls-9"
        d="M596.9,552.9h-1.9v-1.7h1.9V552.9z M596.8,564.4h-1.7v-9.8h1.7V564.4z"
      />
      <path
        className="cls-9"
        d="M603.2,564.6c-0.7,0-1.3-0.1-1.9-0.3c-0.6-0.2-1-0.5-1.5-0.9s-0.7-1-0.9-1.6c-0.2-0.6-0.3-1.4-0.3-2.3
		c0-0.9,0.1-1.6,0.3-2.2s0.5-1.2,0.9-1.6s0.9-0.8,1.4-1c0.6-0.2,1.2-0.3,1.9-0.3c0.5,0,1.1,0.1,1.6,0.2c0.5,0.2,0.9,0.3,1.3,0.5v1.9
		H606c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.3-0.7-0.4c-0.2-0.1-0.5-0.2-0.8-0.3s-0.6-0.1-0.9-0.1c-0.9,0-1.5,0.3-2.1,1
		c-0.5,0.6-0.8,1.6-0.8,2.7c0,1.2,0.2,2.1,0.8,2.7c0.5,0.6,1.2,1,2.1,1c0.5,0,1.1-0.1,1.5-0.3s0.9-0.5,1.3-0.9h0.1v1.9
		c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2c-0.3,0.1-0.5,0.1-0.8,0.2C603.8,564.6,603.5,564.6,603.2,564.6z"
      />
      <path
        className="cls-9"
        d="M611.8,564.6c-1.6,0-2.8-0.4-3.6-1.3s-1.3-2.1-1.3-3.8c0-1.6,0.4-2.9,1.3-3.8s1.9-1.4,3.3-1.4
		c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8s0.6,0.8,0.8,1.4c0.2,0.5,0.3,1.2,0.3,2v0.9h-6.8c0,1.1,0.3,2,0.9,2.6s1.4,0.9,2.4,0.9
		c0.4,0,0.7,0,1.1-0.1s0.7-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.8-0.4s0.4-0.2,0.5-0.4h0.1v1.8c-0.2,0.1-0.4,0.2-0.7,0.3
		c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.2-0.9,0.2C612.5,564.6,612.2,564.6,611.8,564.6z M613.8,558.4c0-0.4-0.1-0.8-0.2-1.2
		s-0.2-0.6-0.4-0.8c-0.2-0.2-0.4-0.4-0.7-0.6s-0.7-0.2-1.1-0.2c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0.3-0.8,0.6
		c-0.2,0.3-0.4,0.5-0.6,0.9c-0.1,0.3-0.2,0.7-0.2,1.1H613.8z"
      />
      <path
        className="cls-9"
        d="M619.7,564.6c-0.7,0-1.3-0.1-1.8-0.3c-0.6-0.2-1-0.4-1.4-0.6v-1.9h0.1c0.1,0.1,0.3,0.2,0.5,0.4
		c0.2,0.1,0.4,0.3,0.8,0.4c0.3,0.1,0.6,0.2,0.9,0.3c0.3,0.1,0.7,0.1,1,0.1c0.3,0,0.5,0,0.8-0.1c0.3-0.1,0.5-0.1,0.7-0.2
		c0.2-0.1,0.4-0.3,0.4-0.4c0.1-0.2,0.1-0.4,0.1-0.7c0-0.4-0.1-0.7-0.3-0.9s-0.6-0.4-1.1-0.5c-0.2-0.1-0.5-0.1-0.8-0.2
		s-0.7-0.1-0.9-0.2c-0.8-0.2-1.3-0.6-1.6-1c-0.3-0.5-0.5-1-0.5-1.6c0-0.9,0.3-1.6,1-2.2s1.6-0.8,2.7-0.8c0.5,0,1.1,0.1,1.6,0.2
		s1,0.3,1.4,0.5v1.8h-0.1c-0.4-0.3-0.9-0.6-1.4-0.8c-0.5-0.2-1-0.3-1.6-0.3c-0.5,0-1,0.1-1.4,0.3s-0.6,0.6-0.6,1
		c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.6,0.4,1.1,0.5c0.2,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.6,0.1,1,0.2c0.7,0.2,1.3,0.5,1.6,0.9
		s0.5,1,0.5,1.7c0,0.4-0.1,0.8-0.3,1.2c-0.2,0.4-0.4,0.7-0.7,1c-0.4,0.3-0.7,0.5-1.2,0.7C620.9,564.5,620.4,564.6,619.7,564.6z"
      />
    </svg>
  );
};

export default ServicesImage;
